import { createClient } from './httpClient'
import config from '@/config/config'

const httpClient = createClient()

const getStatistics = () => {
  return httpClient.get(config.get('api.endpoints.eeccRequests.stats'))
}

export { getStatistics }
