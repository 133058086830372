import config from '@/config/config'
import { createClient } from './httpClient'

import { normalizeRequest, normalizeResponse } from '@/helpers/api.helper'

const httpClient = createClient()

httpClient.interceptors.request.use(normalizeRequest)
httpClient.interceptors.response.use(normalizeResponse)

const getSettings = () => httpClient.get(config.get('api.endpoints.settings.get'))

const putSettings = (settings) => httpClient.put(config.get('api.endpoints.settings.update'), settings)

export { getSettings, putSettings }
