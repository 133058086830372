import { createClient } from './httpClient'
import config from '@/config/config'

const httpClient = createClient()

const getFileUrl = (uuid, download = false) => {
  const url = new URL(config.get('api.endpoints.files.get', { uuid }))

  const urlParams = new URLSearchParams()
  urlParams.set('download', download ? 'true' : 'false')

  url.search = `${urlParams.toString()}`

  return url.toString()
}

const addFile = (file) => {
  const formData = new FormData()
  formData.append('file', file)

  return httpClient.post(config.get('api.endpoints.files.create'), formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export { getFileUrl, addFile }
