import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Keycloak from 'keycloak-js'
import i18n from './i18n'

import config from './config/config'

import 'easymde/dist/easymde.min.css'

import Logger from '@/plugins/logger'
import Icons from '@/plugins/icons'

const isProduction = process.env.NODE_ENV === 'production'

Vue.use(Logger, {
  isProduction
})
Vue.use(Icons)

Vue.config.productionTip = false

const init = async () => {
  await config.loadRuntimeConfig()

  const keycloakInitOptions = {
    url: config.get('keycloak.api.auth'),
    realm: config.get('keycloak.realm'),
    clientId: config.get('keycloak.clientId')
  }

  const keycloakMinValidity = config.get('keycloak.token.minValidity')
  const keycloakRefreshInterval = config.get('keycloak.token.refreshInterval')

  const keycloak = new Keycloak(keycloakInitOptions)

  try {
    const auth = await keycloak.init({ onLoad: 'login-required' })

    if (!auth) {
      window.location.reload()
    } else {
      Vue.cLog('Authenticated')
      await store.dispatch('keycloak/setKeycloak', keycloak)

      new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App)
      }).$mount('#app')

      // Token Refresh
      setInterval(async () => {
        try {
          const refreshed = await keycloak.updateToken(keycloakMinValidity)

          if (refreshed) {
            Vue.cLog('Token refreshed')
          } else {
            Vue.cLog(`Token not refreshed, valid for ${Math.round((keycloak?.tokenParsed?.exp ?? 0) + (keycloak?.timeSkew ?? 0) - new Date().getTime() / 1000)} seconds`)
          }
        } catch (e) {
          Vue.cLog('Failed to refresh token')
        }
      }, keycloakRefreshInterval * 1000)
    }
  } catch (e) {
    Vue.cLog('Authentication Failed')
  }
}

init()
