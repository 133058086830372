import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import upperFirst from 'lodash/upperFirst'
import VueI18n from '../i18n'

import { v4 as uuid } from 'uuid'

const getPriceType = (priceTableEntryPrice, defaultPriceTableEntries) => {
  let result = ''
  const priceKeys = Object.keys(priceTableEntryPrice).sort()
  Object.keys(defaultPriceTableEntries).forEach((priceType) => {
    const keys = Object.keys(defaultPriceTableEntries[priceType]).sort()
    if (JSON.stringify(priceKeys) === JSON.stringify(keys)) {
      result = priceType
    }
  })
  return result
}

const getStateFromTemplate = (template, stateFormData, stateControlData, defaultPriceTableEntries) => {
  const formData = template.template
  const hasRegex = /has([A-Z])/
  const mapRegex = /([a-zA-Z]+)Map/
  const controlData = cloneDeep(stateControlData)
  Object.keys(controlData).forEach((formSegment) => {
    const segmentData = controlData[formSegment]
    if (formData[formSegment]) {
      if (controlData[formSegment][`has${upperFirst(formSegment)}`] !== undefined) {
        controlData[formSegment][`has${upperFirst(formSegment)}`] = true
      }
      Object.keys(segmentData).forEach((field) => {
        let checkMapFields = true
        if (field.match(hasRegex)) {
          checkMapFields = false
          const formFieldName = field.replace(hasRegex, (a, b) => {
            return b.toLocaleLowerCase()
          })
          if (formData[formSegment][formFieldName] !== undefined) {
            let valueWasNull = false
            if (formData[formSegment][formFieldName] === null) {
              valueWasNull = true
              // replace by proper default value
              formData[formSegment][formFieldName] = stateFormData[formSegment][formFieldName]
            }

            if (Array.isArray(formData[formSegment][formFieldName])) {
              controlData[formSegment][field] = formData[formSegment][formFieldName].length > 0
            } else if (formFieldName === 'euFairUseSegment') {
              controlData[formSegment][field] = !valueWasNull
            } else {
              controlData[formSegment][field] = !!formData[formSegment][formFieldName]
            }

            if (Array.isArray(formData[formSegment][formFieldName])) {
              controlData[formSegment][`${formFieldName}Map`] = formData[formSegment][formFieldName]
                .map(() => {
                  return uuid()
                })
            }
          }
        }
        if (checkMapFields && field.match(mapRegex)) {
          const formFieldName = field.replace(mapRegex, (a, b) => {
            return b
          })
          if (Array.isArray(formData[formSegment][formFieldName])) {
            controlData[formSegment][field] = formData[formSegment][formFieldName]
              .map(() => {
                return uuid()
              })
          }
        }
      })
    } else {
      controlData[formSegment][`has${upperFirst(formSegment)}`] = false
      formData[formSegment] = stateFormData[formSegment]
    }
  })
  const priceTable = formData.priceSegment.priceTable
  priceTable.forEach((priceTableEntry, index) => {
    const price = priceTableEntry.price
    if (controlData.priceSegment.priceTable.length < index + 1) {
      controlData.priceSegment.priceTable.push('')
    }
    controlData.priceSegment.priceTable[index] = getPriceType(price, defaultPriceTableEntries)
  })

  const defaultValues = VueI18n.t('global.components.contractSummaryForm.defaultValues')

  Object.keys(defaultValues).forEach(formSegment => {
    Object.keys(defaultValues[formSegment]).forEach(field => {
      if (formData[formSegment][field] === null) {
        formData[formSegment][field] = defaultValues[formSegment][field]
      }
    })
  })

  return {
    formData,
    controlData
  }
}

const transformContractSummaryFormData = (formData, controlData) => {
  const data = cloneDeep(formData)
  const regexHas = /has([A-Z])/
  Object.keys(controlData).forEach((formSegment) => {
    const d = controlData[formSegment]
    Object.keys(d).forEach((field) => {
      if (field.match(regexHas) && typeof d[field] === 'boolean' && !d[field]) {
        const formFieldName = field.replace(regexHas, (a, b) => {
          return b.toLocaleLowerCase()
        })
        data[formSegment][formFieldName] = (Array.isArray(data[formSegment][formFieldName]) || data[formSegment][formFieldName] === null) ? [] : ''
      }
    })
  })
  const regexHeading = /^[a-zA-Z]+Heading$/
  Object.keys(data).forEach((formSegment) => {
    Object.keys(data[formSegment]).forEach((field) => {
      if (field.match(regexHeading) && data[formSegment][field] === '') {
        delete data[formSegment][field]
      }
    })
  })

  const hasBandWidthSegment = get(controlData, ['bandwidthSegment', 'hasBandwidthSegment'])
  if (!hasBandWidthSegment) {
    delete data.bandwidthSegment
  }

  const hasEuFairUseSegment = get(controlData, ['serviceSegment', 'hasEuFairUseSegment'])
  if (!hasEuFairUseSegment) {
    delete data.serviceSegment.euFairUseSegment
  }

  return data
}

export {
  getStateFromTemplate,
  transformContractSummaryFormData
}
