<template>
  <v-snackbar
    :value="hasMessage"
    :color="getColor(messageType)"
    timeout="3000"
    min-width="auto"
    @input="handleInput"
  >
    <div class="d-flex align-center">
      <v-icon class="mr-2">{{ getIcon(messageType) }}</v-icon>
      <span>{{ messageText }}</span>
    </div>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Message from '@/types/Message'

export default {
  name: 'MessageQueue',
  data () {
    return {
      message: null,
      clearingMessage: false
    }
  },
  methods: {
    ...mapActions('messageQueue', ['getNextMessage']),
    handleInput (visible) {
      if (!visible && !this.clearingMessage) {
        this.clearingMessage = true

        // wait for closing animation to finish
        setTimeout(async () => {
          this.message = null
          await this.loadNextMessage()
          this.clearingMessage = false
        }, 200)
      }
    },
    async loadNextMessage () {
      this.message = await this.getNextMessage()
    },
    getColor (type) {
      return Message.colors[type]
    },
    getIcon (type) {
      return Message.icons[type]
    }
  },
  computed: {
    ...mapGetters('messageQueue', ['hasQueue']),
    hasMessage () {
      return !!this.message
    },
    messageType () {
      return this.message?.type ?? null
    },
    messageText () {
      return this.message?.message ?? null
    }
  },
  watch: {
    hasQueue (queue) {
      if (this.message || !queue) {
        return
      }

      this.loadNextMessage()
    }
  }
}
</script>
