<template>
  <v-app>
    <navigation></navigation>

    <v-main>
      <v-container class="py-8">
        <router-view/>
      </v-container>

      <error-display></error-display>
      <message-queue></message-queue>
    </v-main>
  </v-app>
</template>

<script>
import ErrorDisplay from './components/ErrorDisplay'
import Navigation from './components/Navigation'
import MessageQueue from './components/MessageQueue'

export default {
  name: 'App',
  components: {
    ErrorDisplay,
    Navigation,
    MessageQueue
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        const titleKey = `pages.${to.name}.meta.title`
        const defaultTitleKey = 'pages.default.meta.title'

        let title = this.$t(defaultTitleKey)

        if (this.$te(titleKey)) {
          title = `${this.$t(titleKey)} - ${title}`
        }

        document.title = title
      }
    }
  }
}
</script>
