import { createClient } from './httpClient'
import config from '@/config/config'

const httpClient = createClient()

const getUsers = (query = {}) => {
  return httpClient.get(config.get('keycloak.api.users.list'), {
    params: query
  })
}

const createUser = (formData) => {
  return httpClient.post(config.get('keycloak.api.users.create'), formData)
}

const updateUser = (userId, formData) => {
  return httpClient.put(config.get('keycloak.api.users.update', { userId }), formData)
}

const deleteUser = (userId) => {
  return httpClient.delete(config.get('keycloak.api.users.delete', { userId }))
}

const executeActionsEmail = (data) => {
  const userId = data.userId
  const actions = data.actions
  const params = data.params
  return httpClient.put(config.get('keycloak.api.users.sendMail', { userId }), actions, { params })
}

const getUserGroups = (userId) => {
  return httpClient.get(config.get('keycloak.api.users.groups', { userId }))
}

const addUserToGroup = (data) => {
  const userId = data.userId
  const groupId = data.groupId
  return httpClient.put(config.get('keycloak.api.users.addToGroup', { userId, groupId }))
}

const removeUserFromGroup = (data) => {
  const userId = data.userId
  const groupId = data.groupId
  return httpClient.delete(config.get('keycloak.api.users.removeFromGroup', { userId, groupId }))
}

export {
  getUsers,
  createUser,
  updateUser,
  executeActionsEmail,
  deleteUser,
  getUserGroups,
  addUserToGroup,
  removeUserFromGroup
}
