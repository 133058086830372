const documents = {
  meta: {
    title: 'Dokumente',
    heading: 'Dokumente',
    subHeading: 'Hier können Sie statische Vorvertrags-Dokumente hochladen, verwalten und versionieren.'
  },
  components: {
    editFilenameForm: {
      buttons: {
        cancel: 'Abbrechen',
        submit: 'Speichern'
      },
      fields: {
        filename: {
          label: 'Dateiname',
          placeholder: 'Dateiname'
        }
      }
    },
    form: {
      heading: 'Neues Dokument anlegen',
      heading_help: 'Hier können Sie ein neues Dokument anlegen. <ul> <li> Vergeben Sie dazu bitte zunächst eine (möglichst eindeutige) Dokumentenbezeichnung, um das Dokument besser zuordnen zu können. </li> <li> Optional können Sie einen Dateinamen für Ihr Dokument vergeben. Das Dateiformat (z.B. PDF) sowie ein Zeitstempel werden automatisch bei der Anfragenerstellung hinzugefügt. Sofern Sie keinen eigenen Dateinamen angeben, wird der Orginal-Dateiname des Dokumentes verwendet. </li> <li> Optional können Sie zudem eine Bearbeitungsnotiz hinzufügen. </li> <li>Drücken Sie "Speichern", um das Dokument hinzuzufügen. </li> <li>Das Dokument erscheint dann in der Tabelle "Dokumentenverwaltung". Öffnen Sie hier die Detailansicht, um ein Dokument als PDF hochzuladen.</li> </ul>',
      fields: {
        name: {
          label: 'Dokumentenbezeichnung *',
          placeholder: 'Dokumentenbezeichnung'
        },
        filename: {
          label: 'Dateiname',
          placeholder: 'Dateiname'
        },
        fileUuid: {
          text: 'Legen Sie eine Datei hier ab oder <span class="text-decoration-underline blue--text">wählen Sie manuell eine aus</span>. *',
          hint: 'Unterstützte Dateiformate: PDF',
          preview: {
            heading: 'Dokument'
          }
        },
        validFrom: {
          label: 'Startdatum *',
          placeholder: 'Startdatum'
        },
        comment: {
          label: 'Bearbeitungsnotiz',
          placeholder: 'Bearbeitungsnotiz'
        }
      },
      buttons: {
        submit: 'Speichern'
      },
      messages: {
        success: 'Dokument erfolgreich angelegt'
      }
    },
    management: {
      heading: 'Dokumentenverwaltung',
      heading_help: {
        default: 'In dieser Tabelle finden Sie alle gespeicherten Vorvertrags-Dokumente. <ul> <li>Sie haben die Möglichkeit, Dokumente wieder zu löschen (nicht empfohlen), sofern diese noch nicht in eine Dokumentenmappe hinzugefügt wurden. </li> <li> Zudem können Sie aus der Tabelle in die Detail-Ansicht der Dokumente wechseln, um weitere Informationen zu den Dokumenten einzusehen und neue Versionen hinzuzufügen.</li></ul>',
        clerk: 'In dieser Tabelle finden Sie alle gespeicherten Vorvertrags-Dokumente. Sie können zudem aus der Tabelle in die Detail-Ansicht der Dokumente wechseln, um weitere Informationen zu den Dokumenten einzusehen. <p> Bitte beachten Sie, dass Änderungen nur von Nutzern mit den Rollen "Administrator" oder "Manager" vorgenommen werden können. Falls Sie Änderungen vornehmen oder neue Dokumente hinzufügen möchten, wenden Sie sich bitte an Ihren zuständigen Administrator.</p>'
      },
      table: {
        headers: {
          name: 'Name',
          createdOn: 'Erstellt',
          updatedOn: 'Aktualisiert',
          uuid: 'UUID'
        },
        search: {
          label: 'Dokumentensuche',
          hint: 'Suche nach Dokumentennamen und Versionen'
        },
        dialogs: {
          delete: {
            title: 'Dokument "{document}" löschen?',
            buttons: {
              cancel: 'Abbrechen',
              confirm: 'Löschen'
            },
            messages: {
              success: 'Dokument erfolgreich gelöscht',
              error: 'Das Löschen des Dokuments ist fehlgeschlagen. Bitte gehen Sie sicher, dass das Dokument in keiner Dokumentenmappe verwendet wird.'
            }
          }
        },
        tooltips: {
          noDocumentVersions: 'Keine Versionen vorhanden'
        },
        actions: {
          delete: 'Dokument löschen',
          detail: 'Detailansicht öffnen'
        }
      },
      filenameSuffixes: {
        pdfFile: '.pdf'
      },
      dialogs: {
        editFilename: {
          title: 'Dateinamen ändern',
          messages: {
            success: 'Der Dokumentenname wurde erfolgreich gespeichert.',
            error: 'Der Dokumentenname konnte nicht gespeichert werden.'
          }
        }
      },
      noFilenameText: '(Kein Dateiname angegeben)'
    },
    versions: {
      versionsCount: '1 Version | {count} Versionen',
      form: {
        heading: 'Neue Version anlegen',
        heading_help: 'Laden Sie hier eine neue Version Ihres Dokumentes hoch. Legen Sie ein Startdatum fest, ab dem die neue Version gültig sein soll. Beachten Sie, dass die zuletzt erstellte Version ab ihrem Startdatum alle vorherigen Versionen überschreibt.',
        fields: {
          validFrom: {
            label: 'Startdatum *',
            placeholder: 'Startdatum'
          },
          fileUuid: {
            text: 'Legen Sie eine Datei hier ab oder <span class="text-decoration-underline blue--text">wählen Sie manuell eine aus</span>.',
            hint: 'Unterstützte Dateiformate: PDF'
          },
          comment: {
            label: 'Kommentar',
            placeholder: 'Kommentar'
          }
        },
        buttons: {
          submit: 'Speichern'
        },
        messages: {
          success: 'Die neue Version wurde erfolgreich hinzugefügt'
        }
      },
      heading: 'Alle Versionen',
      heading_help: 'Hier finden Sie eine Übersicht aller vorhandenen Versionen des Dokumentes. Die aktuell gültige Version ist grün hinterlegt.',
      table: {
        headers: {
          validFrom: 'Startdatum',
          validTo: 'Enddatum',
          active: 'Aktiv',
          uuid: 'UUID'
        },
        search: {
          label: 'Versionssuche',
          hint: 'Suche nach Startdatum'
        },
        dialogs: {
          delete: {
            title: 'Diese Version löschen?',
            buttons: {
              cancel: 'Abbrechen',
              confirm: 'Löschen'
            },
            messages: {
              success: 'Die Version wurde erfolgreich gelöscht.',
              error: 'Die Version konnte nicht gelöscht werden.'
            }
          }
        },
        actions: {
          delete: 'Version löschen',
          download: 'Version herunterladen'
        },
        overriddenHint: 'Diese Version wird von einer neueren Version mit einer früheren Gültigkeit überschrieben.'
      },
      comments: {
        heading: 'Kommentare'
      }
    }
  }
}

export default documents
