import { createClient } from './httpClient'
import config from '@/config/config'
import { normalizeRequest, normalizeResponse } from '@/helpers/api.helper'

const endpoints = () => config.get('api.endpoints.contractSummaries')

const httpClient = createClient()

httpClient.interceptors.request.use(normalizeRequest)
httpClient.interceptors.response.use(normalizeResponse)

const createContractSummaryHandle = (data) =>
  httpClient.post(endpoints().get('create'), data)

const updateContractSummaryHandle = (templateUuid, data) =>
  httpClient.patch(endpoints().get('update', { templateUuid }), { name: data.name, template: data.template })

const previewContractSummaryHandle = (formData) =>
  httpClient.post(endpoints().get('preview'), formData, {
    headers: { accept: 'application/pdf' },
    responseType: 'blob'
  })

const listContractSummariesHandle = () =>
  httpClient.get(endpoints().get('list'))

const getContractSummaryHandle = (templateUuid) =>
  httpClient.get(endpoints().get('read', { templateUuid }))

const deleteContractSummaryHandle = (templateUuid) =>
  httpClient.delete(endpoints().get('delete', { templateUuid }))

const getDefaultValuesHandle = () =>
  httpClient.get(endpoints().get('defaultValues'))

export {
  createContractSummaryHandle,
  updateContractSummaryHandle,
  previewContractSummaryHandle,
  listContractSummariesHandle,
  getContractSummaryHandle,
  deleteContractSummaryHandle,
  getDefaultValuesHandle
}
