import Vue from 'vue'
import VueI18n from 'vue-i18n'

import config from './config/config'

import messages from '@/locales/messages'

Vue.use(VueI18n)

export default new VueI18n({
  locale: config.get('i18n.locale', {}, 'de'),
  fallbackLocale: config.get('i18n.fallbackLocale', {}, 'de'),
  messages
})
