import {
  createUser,
  updateUser,
  getUsers,
  executeActionsEmail,
  deleteUser,
  getUserGroups,
  addUserToGroup,
  removeUserFromGroup
} from '@/api/users.api'

import {
  getGroups,
  getGroupMembers
} from '@/api/groups.api'

import i18n from '@/i18n'

import config from '@/config/config'

import get from 'lodash/get'

const state = {
  keycloak: null,
  userInfo: null,
  users: [],
  apiResult: null,
  groups: []
}

const getters = {
  isAuthenticated (state) {
    return state.keycloak?.authenticated ?? false
  },
  keycloak (state) {
    return state.keycloak
  },
  logout (state) {
    return state.keycloak?.logout ?? (() => {})
  },
  userInfo (state) {
    return state.userInfo
  },
  email (state) {
    return state.userInfo?.email ?? null
  },
  name (state) {
    if (!state.userInfo) {
      return null
    }

    const userInfo = state.userInfo

    if (userInfo.given_name && userInfo.family_name) {
      return `${userInfo.given_name} ${userInfo.family_name}`
    }

    return state.userInfo?.preferred_username
  },
  userId (state) {
    return state.userInfo?.sub ?? null
  },
  token (state) {
    return state.keycloak?.token
  },
  users (state) {
    return state.users
  },
  groups (state) {
    return state.groups
  },
  isAdmin (state) {
    const clientId = config.get('keycloak.clientId')
    const rName = config.get('keycloak.roles.admin')
    if (rName) {
      const roles = get(state.keycloak.tokenParsed.resource_access, [clientId, 'roles'])
      return roles.indexOf(rName) >= 0
    }
    return false
  },
  isManager (state) {
    const clientId = config.get('keycloak.clientId')
    const rName = config.get('keycloak.roles.manager')
    if (rName) {
      const roles = get(state.keycloak.tokenParsed.resource_access, [clientId, 'roles'])
      return roles.indexOf(rName) >= 0
    }
    return false
  },
  isClerk (state) {
    const clientId = config.get('keycloak.clientId')
    const rName = config.get('keycloak.roles.clerk')
    if (rName) {
      const roles = get(state.keycloak.tokenParsed.resource_access, [clientId, 'roles'])
      return roles.indexOf(rName) >= 0
    }
    return false
  },
  userRole (state) {
    const clientId = config.get('keycloak.clientId')
    const userRoles = get(state.keycloak.tokenParsed.resource_access, [clientId, 'roles'], [])
    const userRole = userRoles.length > 0 ? userRoles[0] : ''
    let role = ''
    const conf = config.get('keycloak.roles').config
    Object.keys(conf)
      .forEach((key) => {
        if (conf[key] === userRole) {
          role = key
        }
      })
    return role
  }
}

const actions = {
  async setKeycloak ({ commit }, keycloak) {
    commit('SET_KEYCLOAK', keycloak)

    const userInfo = await keycloak.loadUserInfo()

    commit('SET_USER_INFO', userInfo)
  },
  async getUsers ({ commit }) {
    const getUsersResponse = await getUsers()
    commit('SET_USERS', getUsersResponse.data)
  },
  async createUser ({ dispatch }, { formData, callee }) {
    const groupId = formData.group
    delete formData.group
    return new Promise((resolve) => {
      createUser(formData).then(() => {
        const email = formData.email
        getUsers({ email }).then((getResponse) => {
          const userId = getResponse.data[0].id
          const user = {
            id: userId,
            requiredActions: ['UPDATE_PASSWORD']
          }
          addUserToGroup({
            userId,
            groupId
          }).then(() => {
            dispatch('fetchUserData')
            dispatch('executeActionsEmail', user)
            resolve(true)
          })
        })
      }).catch((err) => {
        const data = err.response.data
        let errorMessage = data.errorMessage
        data.field = errorMessage.indexOf('email') >= 0 ? 'email' : 'username'
        errorMessage = i18n.t(`global.api.errorMessages.keycloak.${errorMessage}`)
        data.errorMessage = errorMessage
        dispatch('errors/addError', { callee, error: data }, { root: true })
        resolve(false)
      })
    })
  },
  async updateUser ({ dispatch }, { userId, formData, callee }) {
    const groupId = formData.group
    delete formData.group
    delete formData.username
    return new Promise((resolve) => {
      updateUser(userId, formData)
        .then(() => {
          getUserGroups(userId)
            .then((userGroupsResponse) => {
              const userGroupId = userGroupsResponse.data[0].id
              if (userGroupId === groupId) {
                dispatch('fetchUserData')
                resolve(true)
              } else {
                removeUserFromGroup({ userId, groupId: userGroupId })
                  .then(() => {
                    addUserToGroup({ userId, groupId })
                      .then(() => {
                        dispatch('fetchUserData')
                        resolve(true)
                      })
                  })
              }
            })
        }).catch((err) => {
          const data = err.response.data
          let errorMessage = data.errorMessage
          data.field = errorMessage.indexOf('email') >= 0 ? 'email' : 'username'
          errorMessage = i18n.t(`global.api.errorMessages.keycloak.${errorMessage}`)
          data.errorMessage = errorMessage
          dispatch('errors/addError', { callee, error: data }, { root: true })
          resolve(false)
        })
    })
  },
  async deleteUser ({ dispatch }, user) {
    const userId = user.id
    return new Promise((resolve) => {
      deleteUser(userId)
        .then(() => {
          dispatch('fetchUserData')
          resolve(true)
        }).catch((err) => {
          const data = err.response.data
          data.errorMessage = state.translations[data.error] || data.error
          dispatch('errors/addError', { error: data }, { root: true })
          resolve(false)
        })
    })
  },
  async executeActionsEmail ({ commit }, user) {
    await executeActionsEmail({
      userId: user.id,
      actions: user.requiredActions,
      params: {
        client_id: config.get('keycloak.clientId'),
        lifespan: 86400,
        redirect_uri: config.get('baseUrl')
      }
    })
  },
  async getGroups ({ commit }) {
    await getGroups()
      .then((res) => {
        commit('SET_GROUPS', res.data)
      })
  },
  async fetchUserData ({ commit }) {
    const getUserResponse = getUsers()
    const getGroupsResponse = getGroups()
    Promise.all([getUserResponse, getGroupsResponse])
      .then((usersAndGroupsResponse) => {
        const usersObj = usersAndGroupsResponse[0].data
          .reduce((acc, cur) => {
            acc[cur.id] = cur
            return acc
          }, {})
        const groupsObj = usersAndGroupsResponse[1].data
          .reduce((acc, cur) => {
            acc[cur.id] = cur
            return acc
          }, {})
        const helper = []
        Object.keys(groupsObj).forEach((groupId) => {
          helper.push(getGroupMembers(groupId))
        })
        Promise.all(helper)
          .then((helperResponse) => {
            let groupsWithUsers = {}
            helperResponse.forEach((groupWithUsers) => {
              groupsWithUsers = Object.keys(groupWithUsers)
                .reduce((acc, cur) => {
                  acc[cur] = groupWithUsers[cur]
                  return acc
                }, groupsWithUsers)
            })
            Object.keys(groupsWithUsers)
              .forEach((groupId) => {
                const groupName = groupsObj[groupId].name
                groupsWithUsers[groupId]
                  .forEach((user) => {
                    usersObj[user.id].group = groupId
                    usersObj[user.id].groupName = groupName
                  })
              })
            commit('SET_USERS', Object.values(usersObj))
            commit('SET_GROUPS', Object.values(groupsObj))
          })
      })
  }
}

const mutations = {
  SET_KEYCLOAK (state, keycloak) {
    state.keycloak = keycloak
  },
  SET_USER_INFO (state, userInfo) {
    state.userInfo = userInfo
  },
  SET_USERS (state, users) {
    state.users = users
  },
  SET_GROUPS (state, groups) {
    state.groups = groups
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
