import { createClient } from './httpClient'
import { normalizeRequest, normalizeResponse } from '@/helpers/api.helper'
import config from '@/config/config'

const endpoints = () => config.get('api.endpoints.mailTemplates')

const httpClient = createClient()

httpClient.interceptors.request.use(normalizeRequest)
httpClient.interceptors.response.use(normalizeResponse)

const getMailTemplates = () => {
  return httpClient.get(endpoints().get('list'))
}

const createMailTemplate = (data) => {
  return httpClient.post(endpoints().get('create'), data)
}

const updateMailTemplate = (uuid, data) => {
  return httpClient.put(endpoints().get('update', { uuid }), data)
}

const previewMailTemplate = (uuid, email) => {
  return httpClient.post(endpoints().get('preview', { uuid }), { email })
}

const deleteMailTemplate = (uuid) => {
  return httpClient.delete(endpoints().get('delete', { uuid }))
}

export { getMailTemplates, createMailTemplate, updateMailTemplate, previewMailTemplate, deleteMailTemplate }
