import {
  mdiAccount,
  mdiAlertCircleOutline,
  mdiAlertOutline,
  mdiCalendar,
  mdiCheck,
  mdiCheckCircleOutline,
  mdiChevronRight,
  mdiClockOutline,
  mdiClose,
  mdiContentCopy,
  mdiContentDuplicate,
  mdiDelete,
  mdiDomain,
  mdiDotsVertical,
  mdiDownload,
  mdiEmailOutline,
  mdiEmailSyncOutline,
  mdiEye,
  mdiEyeOutline,
  mdiFileDocumentEditOutline,
  mdiFilePdfBox,
  mdiFormatBold,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatItalic,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiFormatQuoteClose,
  mdiFullscreen,
  mdiHelpCircle,
  mdiInformationOutline,
  mdiLink,
  mdiMagnify,
  mdiMinus,
  mdiPencil,
  mdiPlus,
  mdiUndo,
  mdiUpload
} from '@mdi/js'

const Icons = {
  install (Vue) {
    const icons = {
      mdiAccount,
      mdiAlertCircleOutline,
      mdiAlertOutline,
      mdiCalendar,
      mdiCheck,
      mdiCheckCircleOutline,
      mdiChevronRight,
      mdiClockOutline,
      mdiClose,
      mdiContentCopy,
      mdiContentDuplicate,
      mdiDelete,
      mdiDomain,
      mdiDotsVertical,
      mdiDownload,
      mdiEmailOutline,
      mdiEmailSyncOutline,
      mdiEye,
      mdiEyeOutline,
      mdiFileDocumentEditOutline,
      mdiFilePdfBox,
      mdiFormatBold,
      mdiFormatHeader1,
      mdiFormatHeader2,
      mdiFormatHeader3,
      mdiFormatItalic,
      mdiFormatListBulleted,
      mdiFormatListNumbered,
      mdiFormatQuoteClose,
      mdiFullscreen,
      mdiHelpCircle,
      mdiInformationOutline,
      mdiLink,
      mdiMagnify,
      mdiMinus,
      mdiPencil,
      mdiPlus,
      mdiUndo,
      mdiUpload
    }

    Vue.prototype.$icons = Object.assign(icons)

    Vue.prototype.$iconSvgs = Object.keys(icons).reduce((iconSvgs, key) => {
      iconSvgs[key] = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg">
          <path d="${icons[key]}"></path>
        </svg>
      `.trim()
      return iconSvgs
    }, {})
  }
}

export default Icons
