import { createClient } from './httpClient'
import config from '@/config/config'
import { normalizeRequest, normalizeResponse } from '@/helpers/api.helper'

const endpoints = () => config.get('api.endpoints.documentFolders')

const httpClient = createClient()

httpClient.interceptors.request.use(normalizeRequest)
httpClient.interceptors.response.use(normalizeResponse)

const getDocumentFolders = () => httpClient.get(endpoints().get('list'))

const createDocumentFolder = (name, comment = '') =>
  httpClient.post(endpoints().get('create'), { name, comment })

const getDocumentFolder = (uuid) => httpClient.get(endpoints().get('get', { uuid }))

const deleteDocumentFolder = (uuid) => httpClient.delete(endpoints().get('delete', { uuid }))

const createMembership = (
  uuid,
  documentHandleUuid,
  validFrom,
  validTo,
  comment = ''
) => {
  const data = { documentHandleUuid, validFrom, comment }

  if (validTo) {
    data.validTo = validTo
  }

  return httpClient.post(endpoints().get('memberships.create', { uuid }), data)
}

const updateMembership = (
  documentFolderUuid,
  membershipUuid,
  validFrom = null,
  validTo = null,
  comment = ''
) => {
  const data = { comment }

  if (validFrom) {
    data.validFrom = validFrom
  }

  if (validTo) {
    data.validTo = validTo
  }

  return httpClient.put(endpoints().get('memberships.update', { uuid: documentFolderUuid, membershipUuid }), data)
}

const getMemberships = (uuid) => httpClient.get(endpoints().get('memberships.list', { uuid }))

const deleteMembership = (uuid, membershipUuid) => httpClient.delete(endpoints().get('memberships.delete', { uuid, membershipUuid }))

export {
  getDocumentFolders,
  createDocumentFolder,
  getDocumentFolder,
  deleteDocumentFolder,
  createMembership,
  getMemberships,
  deleteMembership,
  updateMembership
}
