import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import has from 'lodash/has'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import mapValues from 'lodash/mapValues'
import merge from 'lodash/merge'

import { createClient } from '@/api/httpClient'

import defaultConfig from './default.config'
import localConfig from './local.config'

const config = merge(cloneDeep(defaultConfig), cloneDeep(localConfig))

export class Config {
  config = {}

  baseConfig = null

  constructor (config, baseConfig = null) {
    this.config = config
    this.baseConfig = baseConfig || this
  }

  async loadRuntimeConfig (defaultConfig = {}) {
    const defaultRuntimeConfig = merge(cloneDeep(defaultConfig), { runtimeConfig: false })

    const httpClient = createClient({
      baseURL: '/',
      validateStatus: (status) => status < 400 || status === 404
    })

    httpClient.interceptors.response.use((res) => {
      if (res.status === 404) {
        res.data = defaultRuntimeConfig
      }

      return res
    })

    let runtimeConfig = {}

    try {
      const { data } = await httpClient.get('config.json')
      runtimeConfig = data
    } catch (e) {
      runtimeConfig = defaultRuntimeConfig
    } finally {
      this.config = merge(cloneDeep(this.config), runtimeConfig)
    }
  }

  get (path, params = {}, defaultValue = null) {
    let value = this.getRaw(path, defaultValue)

    if (isString(value)) {
      value = this.transformConfigString(value, params)
    } else if (isObject(value)) {
      value = this.transformSimpleConfigObject(value, params)
    }

    return value
  }

  getRaw (path, defaultValue = null) {
    return get(this.config, path, defaultValue)
  }

  has (path) {
    return has(this.config, path)
  }

  transformConfigString (value, params) {
    if (!isString(value)) {
      return value
    }

    for (const { groups } of value.matchAll(/(?<placeholder>%(?<key>[\w.]*)%)/gi)) {
      const { key, placeholder } = groups
      const replacement = this.baseConfig.get(key, {}, key)

      value = value.replace(placeholder, isString(replacement) ? replacement : key)
    }

    for (const key in params) {
      value = value.replaceAll(`{${key}}`, params[key])
    }

    return value
  }

  transformSimpleConfigObject (object, params, newConfigObject = true) {
    const transformedObject = mapValues(object, (value) => {
      if (isString(value)) {
        return this.transformConfigString(value, params)
      }

      if (isObject(value)) {
        return this.transformSimpleConfigObject(value, params, false)
      }

      return value
    })

    if (newConfigObject) {
      return new Config(transformedObject, this)
    }

    return transformedObject
  }
}

export default new Config(config)
