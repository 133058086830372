import { createClient } from './httpClient'
import store from '@/store'
import config from '@/config/config'

const httpClient = createClient()

const getAuthToken = () => store.getters['keycloak/token']

const requestInterceptor = (config) => {
  const token = getAuthToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

httpClient.interceptors.request.use(requestInterceptor)

const getGroups = (query = {}) => {
  return httpClient.get(config.get('keycloak.api.groups'), {
    params: query
  })
}

const getGroupMembers = (groupId) => {
  return new Promise((resolve) => {
    httpClient.get(`${config.get('keycloak.api.groups')}/${groupId}/members`)
      .then((response) => {
        const data = {}
        data[groupId] = response.data
        resolve(data)
      })
  })
}

export {
  getGroups,
  getGroupMembers
}
