import {
  getDocumentFolders,
  getDocumentFolder,
  createDocumentFolder,
  deleteDocumentFolder,
  createMembership,
  getMemberships,
  deleteMembership,
  updateMembership
} from '@/api/documentFolders.api'

import { handleError, handleSuccess } from '@/helpers/api.helper'

const state = {
  documentFolders: [],
  memberships: [],
  loading: false,
  creatingMembership: false,
  deletingMembership: false,
  loadingMemberships: false,
  documentFoldersSidePanel: false,
  documentFoldersMembershipsSidePanel: false
}

const getters = {
  documentFolders (state) {
    return state.documentFolders
  },
  loading (state) {
    return state.loading
  },
  creatingMembership (state) {
    return state.creatingMembership
  },
  deletingMembership (state) {
    return state.deletingMembership
  },
  loadingMemberships (state) {
    return state.loadingMemberships
  },
  memberships (state) {
    return state.memberships
  }
}

const actions = {
  async getDocumentFolders ({ commit }) {
    try {
      commit('LOADING', true)

      const { data } = await getDocumentFolders()

      commit('DOCUMENT_FOLDERS', data)
    } catch (e) {
      await handleError(e.response, {})
    } finally {
      commit('LOADING', false)
    }
  },
  async getDocumentFolder ({ commit, getters }, { uuid }) {
    try {
      commit('LOADING', true)

      const documentFolder = getters.documentFolders.find((document) => document.uuid === uuid)

      if (documentFolder) {
        return documentFolder
      }

      const { data } = await getDocumentFolder(uuid)
      return data
    } catch (e) {
      await handleError(e.response, {}, e.message)
    } finally {
      commit('LOADING', false)
    }
  },
  async createDocumentFolder ({ commit, dispatch }, { name, comment = '', handlers = {} }) {
    try {
      commit('LOADING', true)

      const response = await createDocumentFolder(name, comment, handlers)
      await handleSuccess(response, handlers)
      await dispatch('getDocumentFolders')
    } catch (e) {
      await handleError(e.response, handlers, e.message)
    } finally {
      commit('LOADING', false)
    }
  },
  async deleteDocumentFolder ({ commit, dispatch }, { uuid, handlers = {} }) {
    try {
      commit('LOADING', true)

      const response = await deleteDocumentFolder(uuid)
      await handleSuccess(response, handlers)

      await dispatch('getDocumentFolders')
    } catch (e) {
      await handleError(e.response, handlers, e.message)
    } finally {
      commit('LOADING', false)
    }
  },
  async createMembership ({ commit, dispatch }, { folderUuid, documentUuid, validFrom, validTo, comment = '', handlers = {} }) {
    try {
      commit('CREATING_MEMBERSHIP', true)

      const response = await createMembership(folderUuid, documentUuid, validFrom, validTo, comment)
      await handleSuccess(response, handlers)

      await dispatch('getDocumentFolders')
    } catch (e) {
      await handleError(e.response, handlers, e.message)
    } finally {
      commit('CREATING_MEMBERSHIP', false)
    }
  },
  async updateMembership ({ commit }, { membershipUuid, folderUuid, validFrom = null, validTo = null, comment = '', handlers = {} }) {
    try {
      commit('CREATING_MEMBERSHIP', true)

      const response = await updateMembership(folderUuid, membershipUuid, validFrom, validTo, comment, handlers)
      await handleSuccess(response, handlers)
    } catch (e) {
      await handleError(e.response, handlers, e.message)
    } finally {
      commit('CREATING_MEMBERSHIP', false)
    }
  },
  async getMemberships ({ commit }, { uuid, handlers = {} }) {
    try {
      commit('LOADING_MEMBERSHIPS', true)

      const response = await getMemberships(uuid)
      commit('MEMBERSHIPS', response.data)
      await handleSuccess(response, handlers)
    } catch (e) {
      await handleError(e.response, handlers, e.message)
    } finally {
      commit('LOADING_MEMBERSHIPS', false)
    }
  },
  async deleteMembership ({ commit, dispatch }, { uuid, membershipUuid, handlers = {} }) {
    try {
      commit('DELETING_MEMBERSHIP', true)

      const response = await deleteMembership(uuid, membershipUuid)
      await handleSuccess(response, handlers)

      await dispatch('getDocumentFolders')
    } catch (e) {
      await handleError(e.response, handlers, e.message)
    } finally {
      commit('DELETING_MEMBERSHIP', false)
    }
  },
  setDocumentFoldersSidePanel ({ commit }, sidePanel) {
    commit('SET_DOCUMENT_FOLDERS_SIDE_PANEL', sidePanel)
  },
  setDocumentFoldersMembershipsSidePanel ({ commit }, sidePanel) {
    commit('SET_DOCUMENT_FOLDERS_MEMBERSHIPS_SIDE_PANEL', sidePanel)
  }
}

const mutations = {
  DOCUMENT_FOLDERS (state, items) {
    state.documentFolders = items
  },
  LOADING (state, loading) {
    state.loading = !!loading
  },
  CREATING_MEMBERSHIP (state, loading) {
    state.creatingMembership = !!loading
  },
  DELETING_MEMBERSHIP (state, loading) {
    state.deletingMembership = !!loading
  },
  LOADING_MEMBERSHIPS (state, loading) {
    state.loadingMemberships = !!loading
  },
  MEMBERSHIPS (state, memberships) {
    state.memberships = memberships
  },
  SET_DOCUMENT_FOLDERS_SIDE_PANEL (state, sidePanel) {
    state.documentFoldersSidePanel = sidePanel
  },
  SET_DOCUMENT_FOLDERS_MEMBERSHIPS_SIDE_PANEL (state, sidePanel) {
    state.documentFoldersMembershipsSidePanel = sidePanel
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
