const createTemplate = {
  meta: {
    title: 'VZF-Vorlage erstellen',
    heading: 'VZF-Vorlage erstellen',
    subHeading: 'Erstellen Sie VZF-Vorlagen für bestimmte Produkte oder Produktgruppen. Sie können diese später bei der Erstellung von Anfragen aufrufen und so die Anfragen-Erstellung erleichtern. Vorlagen können im Rahmen der Anfragen-Erstellung weiter angepasst werden.'
  },
  form: {
    baseSegment: {
      labels: {
        mainFont: 'Schriftart'
      }
    }
  },
  components: {
    buttons: {
      backButton: {
        text: 'Zurück zur Vorlagenverwaltung'
      }
    },
    dialogs: {
      leaveRouteConfirmation: {
        title: 'Bearbeitung abbrechen',
        text: 'Möchten Sie die Bearbeitung der VZF-Vorlage abbrechen? Ihre Daten werden nicht gespeichert.',
        buttons: {
          cancel: 'Weiter bearbeiten',
          confirm: 'Bearbeitung abbrechen'
        }
      }
    }
  }
}

export default createTemplate
