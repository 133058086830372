import Vue from 'vue'
import Vuex from 'vuex'

import contractSummaries from './modules/contractSummaries.module'
import documentFolders from './modules/documentFolders.module'
import documents from './modules/documents.module'
import errors from './modules/errors.module'
import keycloak from './modules/keycloak.module'
import mailTemplates from './modules/mailTemplates.module'
import messageQueue from './modules/messageQueue.module'
import requests from './modules/requests.module'
import settings from './modules/settings.module'
import statistics from './modules/statistics.module'
import markdownPages from './modules/markdownPages.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    contractSummaries,
    documentFolders,
    documents,
    errors,
    keycloak,
    mailTemplates,
    messageQueue,
    requests,
    settings,
    statistics,
    markdownPages
  }
})
