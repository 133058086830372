const users = {
  meta: {
    title: 'Benutzerverwaltung',
    heading: 'Benutzerverwaltung',
    subHeading: 'Auf dieser Seite können Sie neue Nutzer anlegen und vorhandene Nutzer verwalten.'
  },
  components: {
    management: {
      heading: 'Benutzer verwalten',
      table: {
        headers: {
          username: 'Benutzername',
          createdOn: 'Erstellt',
          id: 'UUID',
          firstName: 'Vorname',
          lastName: 'Nachname',
          fullName: 'Name',
          email: 'E-Mail-Adresse',
          groupName: 'Gruppe'
        },
        actions: {
          sendActionMail: 'Passwort ändern E-Mail versenden',
          delete: 'Benutzer löschen',
          update: 'Benutzer bearbeiten'
        },
        search: {
          label: 'Benutzer suchen',
          hint: 'Suche nach Benutzern'
        }
      },
      dialogs: {
        create: {
          title: 'Benutzer erstellen'
        },
        update: {
          title: 'Benutzer bearbeiten'
        },
        delete: {
          title: 'Benutzer löschen',
          text: 'Möchten Sie den Benutzer "{username}" endgültig löschen? Diese Aktion kann nicht rückgängig gemacht werden.',
          cancelButtonText: 'Abbrechen',
          deleteButtonText: 'Löschen'
        },
        sendActionMail: {
          title: 'E-Mail versenden',
          text: 'Möchten Sie dem Benutzer "{username}" eine E-Mail mit der Aufforderung zur Änderung seines Passworts senden?',
          cancelButtonText: 'Abbrechen',
          deleteButtonText: 'Senden'
        }
      },
      snackbars: {
        deleteUserSuccessText: 'Der Benutzer wurde erfolgreich gelöscht',
        createUserSuccessText: 'Der Benutzer wurde erfolgreich erstellt',
        updateUserSuccessText: 'Der Benutzer wurde erfolgreich bearbeitet',
        sendActionMailSuccessText: 'Die E-Mail wurde erfolgreich versendet'
      },
      buttons: {
        createUserButtonText: 'Benutzer erstellen'
      }
    },
    addUserForm: {
      fields: {
        username: {
          label: 'Benutzername*',
          placeholder: 'Benutzername'
        },
        email: {
          label: 'E-Mail-Adresse*',
          placeholder: 'E-Mail-Adresse'
        },
        firstName: {
          label: 'Vorname*',
          placeholder: 'Vorname'
        },
        lastName: {
          label: 'Nachname*',
          placeholder: 'Nachname'
        },
        group: {
          label: 'Gruppe*',
          placeholder: 'Gruppe'
        }
      },
      buttons: {
        cancel: 'Abbrechen',
        submit: 'Erstellen'
      },
      groups: {
        Administrator: 'Administrator',
        Manager: 'Manager',
        Clerk: 'Clerk'
      }
    },
    editUserForm: {
      fields: {
        username: {
          label: 'Benutzername*',
          placeholder: 'Benutzername'
        },
        email: {
          label: 'E-Mail-Adresse*',
          placeholder: 'E-Mail-Adresse'
        },
        firstName: {
          label: 'Vorname*',
          placeholder: 'Vorname'
        },
        lastName: {
          label: 'Nachname*',
          placeholder: 'Nachname'
        },
        group: {
          label: 'Gruppe*',
          placeholder: 'Gruppe'
        }
      },
      buttons: {
        cancel: 'Abbrechen',
        submit: 'Speichern'
      },
      groups: {
        Administrator: 'Administrator',
        Manager: 'Manager',
        Clerk: 'Clerk'
      }
    }
  }
}

export default users
