import {
  getMarkdownPageHandle,
  updateMarkdownPageHandle
} from '@/api/markdownPages.api'

import i18n from '@/i18n'

const state = {
  markdownPage: null,
  loading: false
}

const getters = {
  markdownPage (state) {
    return state.markdownPage
  },
  loading (state) {
    return state.loading
  }
}

const actions = {
  async getMarkdownPage ({ commit, dispatch }, markdownPageUuid) {
    commit('LOADING', true)
    try {
      const response = await getMarkdownPageHandle(markdownPageUuid)
      dispatch('setMarkdownPage', response.data)
      return response
    } catch (e) {
      console.error(e)
    } finally {
      commit('LOADING', false)
    }
  },
  async updateMarkdownPage ({ commit, dispatch }, { markdownPageUuid, data }) {
    commit('LOADING', true)
    try {
      const response = await updateMarkdownPageHandle({ markdownPageUuid, data })
      return await dispatch('getMarkdownPage', markdownPageUuid)
        .then(() => {
          dispatch('messageQueue/queueSuccess', i18n.t('pages.help.components.management.messages.success'), { root: true })
          return response
        })
    } catch (e) {
      let errorMessage = e.response.statusText
      errorMessage = i18n.t(`global.api.errorMessages.markdown.${errorMessage}`)
      dispatch('messageQueue/queueError', errorMessage, { root: true })
      return e.response
    } finally {
      commit('LOADING', false)
    }
  },
  setMarkdownPage ({ commit }, markdownPage) {
    commit('SET_MARKDOWN_PAGE', markdownPage)
  }
}

const mutations = {
  SET_MARKDOWN_PAGE (state, markdownPage) {
    state.markdownPage = markdownPage
  },
  LOADING (state, loading) {
    state.loading = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
