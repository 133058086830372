<template>
  <div>
    <v-app-bar
      color="primary"
      dark
    >
      <template v-if="logo">
        <router-link
          custom
          :to="{ name: 'home' }"
          v-slot="{ href, navigate }"
        >
          <a :href="href" @click="navigate" class="d-flex align-center">
            <img
              alt="Logo"
              class="mr-2 my-2"
              height="48"
              width="auto"
              :src="logo"
            />
          </a>
        </router-link>

        <v-divider
          class="mx-4 hidden-md-and-down"
          vertical
        ></v-divider>
      </template>

      <v-btn
        v-for="({ name }, i) in navComputed"
        :key="i"
        :to="{ name }"
        text
        small
        class="hidden-md-and-down mr-1"
      >
        {{ getPageTitle(name) }}
      </v-btn>

      <v-spacer></v-spacer>

      <v-menu
        bottom
        left
        :min-width="200"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
            class="hidden-md-and-down"
          >
            <v-icon>{{ $icons.mdiAccount }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :to="{ name: 'help' }">
            {{ helpText }}
          </v-list-item>
          <v-list-item :href="getAccountPageUrl()">
              {{ name }}
          </v-list-item>
          <v-list-item @click="logout">
            {{ logoutText }}
          </v-list-item>
        </v-list>
      </v-menu>

      <v-app-bar-nav-icon @click="openDrawer" class="hidden-lg-and-up"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      right
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          color="primary"
        >
          <v-list-item
            v-for="({ name }, i) in navComputed"
            :key="i"
            :to="{ name }"
          >
            <v-list-item-content>
              <v-list-item-title>{{ getPageTitle(name) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
        dense
      >
        <v-list-item-group
          color="primary"
        >
          <v-list-item :to="{ name: 'help' }" @click="closeDrawer">
            <v-list-item-content>
              <v-list-item-title>{{ helpText }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :href="getAccountPageUrl()" @click="closeDrawer">
            <v-list-item-content>
              <v-list-item-title>{{ name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout()">
            <v-list-item-content>
              <v-list-item-title>{{ logoutText }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import get from 'lodash/get'
import upperFirst from 'lodash/upperFirst'

export default {
  name: 'Navigation',
  data () {
    return {
      nav: [
        { name: 'requests' },
        { name: 'documents' },
        { name: 'documentFolders' },
        { name: 'settings' },
        { name: 'templates' },
        { name: 'users' }
      ],
      drawer: false
    }
  },
  created () {
    this.fetchSettings()
  },
  methods: {
    ...mapActions('settings', ['fetchSettings']),
    openDrawer () {
      this.drawer = true
    },
    closeDrawer () {
      this.drawer = false
    },
    getAccountPageUrl () {
      return this.keycloak.createAccountUrl()
    },
    getPageTitle (page) {
      return this.$t(`pages.${page}.meta.title`)
    }
  },
  computed: {
    ...mapGetters('keycloak', ['logout', 'keycloak', 'name', 'isAdmin', 'isManager', 'isClerk']),
    ...mapGetters('settings', ['logo']),
    helpText () {
      return this.$t('global.components.navigation.actions.help')
    },
    logoutText () {
      return this.$t('global.components.navigation.actions.logout')
    },
    navComputed () {
      const allRoutes = this.$router.getRoutes()
      return this.nav
        .filter((navItem) => {
          const routeIndex = allRoutes.findIndex((route) => {
            return route.name === navItem.name
          })
          const roles = get(allRoutes[routeIndex], ['meta', 'roles'], [])
          if (roles.length === 0) {
            return true
          }
          return roles
            .reduce((acc, role) => {
              const methodName = `is${upperFirst(role)}`
              return acc || this[methodName]
            }, false)
        })
    }
  }
}
</script>
