const global = {
  requests: {
    states: {
      accepted: 'Angenommen',
      canceled: 'Abgebrochen',
      declined: 'Abgelehnt',
      expired: 'Abgelaufen',
      open: 'Offen',
      transitions: {
        accepted: 'Annehmen',
        canceled: 'Abbrechen',
        declined: 'Ablehnen'
      }
    }
  },
  customers: {
    types: {
      business: 'Geschäftskunde',
      private: 'Privatkunde'
    }
  },
  components: {
    navigation: {
      actions: {
        help: 'Hilfe und Support',
        logout: 'Ausloggen'
      }
    },
    embeddedObject: {
      heading: 'Vorschau',
      preview: 'Vorschau ansehen',
      close: 'Vorschau schließen',
      download: 'Herunterladen'
    },
    copy: {
      success: 'Erfolgreich in die Zwischenablage gelegt'
    },
    documentSelection: {
      overline: 'Versionen',
      versions: {
        active: 'Aktiv',
        validity: '{start} - {end}',
        openValidity: '{start}'
      }
    },
    dataTable: {
      loadingText: 'Daten werden geladen...',
      noDataText: 'Keine Daten vorhanden',
      noResultsText: 'Keine Suchergebnisse für "{0}"',
      search: {
        minLength: 'Der Suchbegriff muss mindestens {min} Zeichen lang sein'
      },
      sorting: {
        sortBy: 'Sortieren nach'
      },
      buttons: {
        copyButton: {
          tooltip: 'Kopieren'
        }
      }
    },
    helpDialog: {
      toggleButton: 'Hilfetext anzeigen',
      closeButton: 'OK'
    },
    contractSummaryTemplateSelect: {
      label: 'Vorlage auswählen',
      noTemplateText: 'Keine Vorlage'
    },
    contractSummaryForm: {
      heading: 'Vertragszusammenfassung erstellen',
      startButton: 'Jetzt VZF erstellen',
      previewTemplateButton: 'Vorschau',
      previewDialogTitle: 'Vorschau',
      pdfPreview: 'PDF-Vorschau anzeigen',
      pdfPreviewTooltip: 'PDF-Vorschau anzeigen',
      saveTemplateButton: 'Als Vorlage speichern',
      updateTemplateButton: 'Änderungen speichern',
      submitButton: 'VZF erstellen',
      nextButton: 'Weiter',
      nextButtonTooltip: 'Zum nächsten Abschnitt',
      setDefaultValueButton: 'Standardwert wiederherstellen',
      mandatoryFieldsText: 'Alle mit * gekennzeichneten Felder sind Pflichtfelder',
      baseSegment: {
        headings: {
          general: 'Allgemeine Angaben',
          general_help: 'In jeder Vertragszusammenfassung muss eine Überschrift, ein Einleitungstext sowie der Produktname angegeben werden.<ul> <li>Die Überschrift sowie der Einleitungstext (inkl. Fußnote) sind vom Gesetzgeber vorgeschrieben. Eine Änderung wird daher nicht empfohlen.</li> <li>Die Position der Fußnote im Einleitungstext wird mit dem Symbol `[^]` markiert. Wird dieses Symbol aus dem Einleitungstext entfernt, erscheint die Fußnote automatisch am Ende des Textes.</li> <li>Geben Sie in dem Feld "Produktname" den (Marken-)Namen des entsprechenden Tarifs an (**Beispiel:** Mobilfunk Green mit 15 GB & Flatrate in alle dt. Netze).</li> <li>Achten Sie beim Ausfüllen des gesamten Formulars darauf, kurze Sätze und eine leicht verständliche Sprache zu verwenden. Verzichten Sie auf Abkürzungen und Fachsprache.</li> </ul>'
        },
        labels: {
          productName: 'Produktname *',
          introText: 'Einleitungstext',
          introHeading: 'Überschrift',
          footnoteText: 'Fußnote',
          mainFont: 'Schriftart'
        }
      },
      serviceSegment: {
        headings: {
          general: 'Dienste und Geräte',
          general_help: `Geben Sie in diesem Segment alle Hauptmerkmale des entsprechenden Tarifs an.

**Beispiele**:

- Mobilfunk-Sprachtelefonie
- Mobilfunk-Internetzugang
- Fernsehübertragungsdienst
- Art der enthaltenen Endgeräte
- etc.`,
          features: 'Dienste',
          features_help: 'Geben Sie hier alle Dienste an, die in dem entsprechenden Tarif enthalten sind. <ul> <li>**Beispiel:** Festnetz-Telefonie, etc.</li> <li>Mit dem Plus-Button können Sie weitere Dienste hinzufügen.</li> <li>Bei komplexen Bündelprodukten genügt es, Bündelteile nur stichwortartig darzustellen und auf separate Dokumente zu verweisen, sofern sie keinen Kommunikationsdienst betreffen.</li> </ul>',
          devices: 'Geräte',
          devices_help: 'Geben Sie hier alle Geräte an, die in dem entsprechenden Tarif enthalten sind. <ul> <li>Geben Sie dabei die Marke, den Typ und ggf. den Hersteller des Gerätes an (z.B. Smartphone XX, schwarz, 64 GB).</li> <li>Eine exakte Gerätebezeichnung muss nur dann angegeben werden, wenn das Gerät Eigentum Ihres Kunden werden soll.</li> <li>Sind in Ihrem Tarif keine Geräte enthalten, müssen Sie dieses Feld nicht ausfüllen.</li></ul>',
          volumes: 'Leistungen',
          volumes_help: 'Geben Sie hier (falls zutreffend) das im Vertrag inkludierte Volumen bzw. die inkludierte Menge sowie die Taktung von Anrufen, Nachrichten und Daten an ("Blockrounding").<ul> <li> **Beispiel:** Leistung: "Sprachtelefonie" | Taktung: "60/60" | Menge/Volumen pro Abrechnungszeitraum: "120 Minuten". </li> <li>Achten Sie bei Zahlenfeldern darauf, die entsprechende Einheit mit anzugeben (z.B. Minuten).</li> </ul>',
          tvChannels: 'TV Kanäle',
          tvChannels_help: 'Grundsätzlich sind hier alle im Dienst enthaltene TV-Kanäle aufzuführen. <ul> <li>Sollte dies aufgrund des Umfangs nicht möglich sein, kann mit einem Link auf ein Dokument verwiesen werden, welches eine vollständige Auflistung der Kanäle enthält.</li> <li> Wenn Sie einen Link anzeigen möchten, haben Sie die Möglichkeit, diesen im folgenden Format in den Textfeldern des Formulars einzufügen: ```[Text](https://www.google.com)``` </li> <li>Sollte der Tarif keinen Fernsehübertragungsdienst enthalten, muss dieses Feld nicht ausgefüllt werden.</li> </ul>',
          addFeatures: 'Dienste hinzufügen',
          addDevices: 'Geräte hinzufügen',
          addVolumes: 'Leistungen hinzufügen',
          addTvChannels: 'TV Kanäle auflisten',
          euFairUseHeading: 'Informationen zur Fair Use Policy',
          euFairUseHeading_help: 'Sofern die Nutzung des Dienstes im Ausland möglich ist und es Einschränkungen für die Nutzung des Dienstes im Ausland gibt, muss auf die Fair Use Policy beim EU-Roaming (inkl. Angabe des im Ausland verfügbaren Datenvolumens) verwiesen werden. Für den exakten Inhalt der Fair Use Policy genügt es, auf die Website des Anbieters der Fair Use Policy (exakte Link-Adresse) zu verweisen. <p> Wenn Sie einen Link anzeigen möchten, haben Sie die Möglichkeit, diesen im folgenden Format in den Textfeldern des Formulars einzufügen: ```[Text](https://www.google.com)```</p>'
        },
        featuresHeading: 'Dienste',
        labels: {
          heading: 'Überschrift für den Abschnitt "Dienste und Geräte"',
          featuresHeading: 'Überschrift für den Abschnitt "Dienste"',
          features: 'Dienst *',
          devicesHeading: 'Überschrift für den Abschnitt "Geräte"',
          devices: 'Gerät *',
          volumeServiceHeading: 'Tabellenüberschrift für die Spalte "Leistung"',
          volumeMeteringHeading: 'Tabellenüberschrift für die Spalte "Taktung"',
          volumeVolumeHeading: 'Tabellenüberschrift für die Spalte "Menge / Volumen pro Abrechnungszeitraum"',
          volumes: {
            service: 'Leistung *',
            metering: 'Taktung',
            volume: 'Menge / Volumen pro Abrechnungszeitraum *'
          },
          hasEuFairUseSegment: 'Informationen zur Nutzung der Dienste im Ausland hinzufügen',
          euFairUseSegment: {
            possibleText: 'Angezeigter Text zur Nutzung im EU-Ausland',
            isPossible: 'Nutzung des Tarifs im EU-Ausland möglich?',
            isPossibleRadio: {
              true: 'Ja',
              false: 'Nein'
            },
            restrictedText: 'Angezeigter Text zu den Einschränkungen',
            isRestricted: 'Wenn ja, gibt es dabei Einschränkungen?',
            isRestrictedRadio: {
              true: 'Ja',
              false: 'Nein'
            },
            referenceText: 'Beschreibung der Einschränkungen *'
          },
          tvChannelsHeading: 'Überschrift für den Abschnitt "TV Kanäle"',
          tvChannelsText: 'TV Kanäle *',
          hasDevices: 'Geräte hinzufügen',
          hasVolumes: 'Leistungen hinzufügen',
          hasTvChannelsText: 'TV Kanäle hinzufügen'
        },
        buttons: {
          features: {
            addButtonTitle: 'Dienst hinzufügen',
            removeButtonTitle: 'Diesen Dienst entfernen'
          },
          devices: {
            addButtonTitle: 'Gerät hinzufügen',
            removeButtonTitle: 'Dieses Gerät entfernen'
          },
          volumes: {
            addButtonTitle: 'Leistung hinzufügen',
            removeButtonTitle: 'Diese Leistung entfernen'
          }
        }
      },
      bandwidthSegment: {
        headings: {
          general: 'Geschwindigkeiten des Internetdienstes und Abhilfen bei Problemen',
          general_help: 'Geben Sie in diesem Feld die Geschwindigkeit Ihres Internetdienstes sowie die entsprechende Rechtsbehelfsbelehrung (bzw. Vorgehen bei Problemen) an. <ul> <li>Falls der Tarif keinen Internetzugangsdienst umfasst, muss dieses Segment nicht ausgefüllt werden.</li> <li>Beachten Sie, dass sich die erforderlichen Angaben bei Festnetzinternetzugängen und Mobilfunkinternetzugängen unterscheiden. Wählen Sie die richtige Kategorie aus. Bei Bündelverträgen können ggf. sowohl Festnetz- als auch Mobilfunkinternetzugänge enthalten sein.</li></ul>',
          mobileBandwidth: 'Bandbreite Mobilfunk',
          mobileBandwidth_help: 'Bei Mobilfunkinternetzugängen ist die geschätzte maximale Bandbreite/Geschwindigkeit sowohl für den Download als auch für den Upload anzugeben. Geben Sie bitte auch die passende Einheit an (z.B. 100 Mbit/s).',
          landlineBandwidth: 'Bandbreite Festnetz',
          landlineBandwidth_help: 'Bei Festnetzinternetzugängen ist die minimale, normalerweise zur Verfügung stehende und maximale Bandbreite/Geschwindigkeit sowohl für den Upload als auch für den Download anzugeben. Geben Sie bitte auch die passende Einheit an (z.B. 100 Mbit/s).',
          remedyText: 'Rechtsbehelfe',
          remedyText_help: 'Geben Sie hier zusammenfassend die Rechtsbehelfe an, die dem Verbraucher nach nationalem Recht im Falle einer kontinuierlichen oder regelmäßig wiederkehrenden Abweichung von der angegebenen Bandbreite/Geschwindigkeit oder von anderen angegebenen Dienstqualitätsparametern zur Verfügung stehen. \nDer vorausgefüllte Standardtext stellt ein Beispiel für eine mögliche Beschreibung des Rechtsbehelfs dar. Ggf. stehen dem Verbraucher noch weitere Möglichkeiten offen (z.B. Beantragung eines Schlichtungsverfahrens mit Ihrem Unternehmen).'
        },
        labels: {
          heading: 'Überschrift für den Bereich "Geschwindigkeiten des Internetdienstes und Abhilfen bei Problemen"',
          mobileHeading: 'Überschrift für den Bereich "Mobilfunk"',
          mobileColumnHeading: 'Tabellenüberschrift für die Spalte "Mobilfunk"',
          mobileBandwidth: {
            download: 'Bandbreite Download *',
            upload: 'Bandbreite Upload *'
          },
          landlineHeading: 'Überschrift für den Bereich "Festnetz"',
          landlineColumnHeading: 'Tabellenüberschrift "Bandbreite"',
          landlineColumnMinHeading: 'Tabellenüberschrift "minimale Bandbreite"',
          landlineColumnAvgHeading: 'Tabellenüberschrift "normale Bandbreite"',
          landlineColumnMaxHeading: 'Tabellenüberschrift "maximale Bandbreite"',
          landlineBandwidth: {
            0: {
              download: 'minimale Bandbreite Download *',
              upload: 'minimale Bandbreite Upload *'
            },
            1: {
              download: 'normale Bandbreite Download *',
              upload: 'normale Bandbreite Upload *'
            },
            2: {
              download: 'maximale Bandbreite Download *',
              upload: 'maximale Bandbreite Upload *'
            }
          },
          remedyText: 'Rechtsbehelfe *',
          hasBandwidthSegment: 'Angaben zur Bandbreite machen',
          hasLandlineBandwidth: 'Bandbreite Festnetz angeben',
          hasMobileBandwidth: 'Bandbreite Mobilfunk angeben'
        }
      },
      priceSegment: {
        headings: {
          general: 'Preis',
          general_help: 'In diesem Segment müssen alle Preise gesondert aufgelistet werden, die für den entsprechenden Tarif relevant sind. Dazu gehören einmalige Entgelte (z.B. für die Aktivierung des Dienstes) sowie wiederkehrende und verbrauchsabhängige Entgelte. <p>Sofern Sie wiederkehrende Preise in der Preistabelle auflisten möchten, geben Sie bitte auch den Abrechnungszeitraum in Tagen an. </p>',
          priceTableNote: 'Hinweistext für Preistabelle',
          priceTableNote_help: 'Hier können Sie weitere Hinweise zur Preistabelle angeben, wie z.B., dass es sich bei den angegebenen Preisen um Nettopreise handelt.',
          priceTable: 'Preistabelle',
          priceTable_help: 'Listen Sie hier alle für den Tarif relevanten, verbrauchsunabhängigen Preise gesondert als einzelne Posten auf (inkl. aller Steuern), z.B.: <ul> <li>(einmalige) Aktivierungsentgelte (fällig vor oder bei Beginn der Diensterbringung)</li> <li>wiederkehrende Entgelte pro Abrechnungszeitraum und pro Monat (30 Tage)</li> <li>Preisnachlässe (z.B. Rabatte, Young-Tarif, Tarifreduktion, etc.)</li> <li>(reduzierte) Gerätepreise</li> <li>(zeitliche begrenzte) Sonderangebotspreise.</li> <li>etc.</li></ul> <br /> <p>Beachten Sie bitte folgende Punkte: <ul> <li> Geben Sie bitte auch die passende Einheit an (z.B. 10€, 19ct).</li> <li>Mit dem Plus-Button können Sie weitere Posten hinzufügen.</li> <li>Wählen Sie für jeden Preis bzw. Preisnachlass aus, ob es sich um ein einmaliges oder wiederkehrendes Entgelt handelt. Beachten Sie bei wiederkehrenden Entgelten, dass sowohl der Preis pro Kalendermonat (30 Tage) als auch der Preis pro Abrechnungszeitraum angegeben werden muss (**Beispiel:** Der Abrechnungszeitraum beträgt ein Jahr - Preis pro Abrechnungszeitraum: 120€ | Preis pro Monat: 10€). In vielen Fällen werden sich diese beiden Angaben nicht unterscheiden.</li> <li>Preisnachlässe werden als eigene Posten aufgeführt und als negativer Wert in das Preisfeld eingetragen. Die Dauer des Preisnachlasses kann in das Feld "Gültigkeit" eingetragen werden. Gleiches gilt für zeitlich begrenzte Sonderangebote. Hier muss die Gültigkeit des Angebots zusätzlich aufgeführt werden (s. "Bedingungen für die Inanspruchnahme des Dienstes zum angegebenen Preis"). Zudem muss der vollständige Preis ohne Inanspruchnahme des Sonderangebots angegeben werden.</li> <li>Handelt es sich bei den Gerätepreisen um reduzierte Preise, muss dies für den Kunden ersichtlich sein.</li></ul> </p>',
          priceVolumeTable: 'Verbrauchsabhängige Entgelte bei Überschreitung der inkludierten Leistung',
          priceVolumeTable_help: 'Hierbei handelt es sich um Entgelte, die anfallen, wenn die im Grundentgelt enthaltenen Leistungen (z.B. Freiminuten, enthaltenes Datenvolumen, etc.) überschritten werden. <ul> <li>Auslandseinheiten müssen daher nur dann angegeben werden, wenn diese auch im Grundentgelt inkludiert sind.</li> <li>Bei einem Tarif, der als "unbeschränkt" angegeben wird (z.B. "Telefonie-Flatrate"), aber tatsächlich nur beispielsweise 10.000 Minuten enthält, muss das Entgelt für zusätzliche Einheiten nach der Überschreitung der 10.000 Freiminuten aufgeführt werden.</li> <li>Achten Sie darauf, in dem Preis-Feld auch die passende Einheit anzugeben (z.B. 19ct/Minute).</li> <li> **Beispiel:** Leistung: "Gesprächsminuten" | Taktung: "60/60" | Preis: "0,19 €/Minute"</li></ul>',
          otherPrices: 'Sonstige Entgelte',
          otherPrices_help: 'Listen Sie hier weiteren Entgelte auf, die nicht in der obigen Preistabelle enthalten sind. Es reicht aus, wenn Sie auf ein separates Dokument verweisen, welches in den Vorvertraglichen Informationen enthalten ist (z.B. Link). Dieses Dokument muss sämtliche Entgelte enthalten, die in den Vertrag einbezogen werden sollen. <p> Wenn Sie einen Link anzeigen möchten, haben Sie die Möglichkeit, diesen im folgenden Format in den Textfeldern des Formulars einzufügen: ```[Text](https://www.google.com)```</p>',
          priceConditions: 'Bedingungen für die Inanspruchnahme des Dienstes zum angegebenen Preis',
          priceConditions_help: 'Geben Sie hier die Bedingungen an, die der Kunden für die Inanspruchnahme des Dienstes zum angegebenen Preis erfüllen muss, z.B.: <ul> <li>Geschäftsmodelle, welche eine bestimmte Gegenleistung vorsehen </li> <li> Vorlage GIS-Bescheid (für Sozialtarife) </li> <li> Bestehen eines bestimmten Anschlusses/Vertragsverhältnisses </li> <li> Nachweis über Unternehmenszugehörigkeit (Mitarbeitertarife) </li> <li> Nachweis über das Alter (Jugendtarife) </li> <li> Geltungsdauer eines bestimmten Sonderangebots </li> <li> etc.</li></ul>'
        },
        labels: {
          heading: 'Überschrift für den Bereich "Preis"',
          invoiceLineHeading: 'Tabellenüberschrift für die Spalte "Leistung"',
          priceCycle: 'Abrechnungszeitraum',
          priceHeading: 'Tabellenüberschrift für die Spalte "Preis"',
          priceOneTimeHeading: 'Tabellenüberschrift für die Spalte "einmalig"',
          pricePerCycleHeading: 'Tabellenüberschrift für die Spalte "pro 28 Tage"',
          pricePerMonthHeading: 'Tabellenüberschrift für die Spalte "pro Monat"',
          validityHeading: 'Tabellenüberschrift für die Spalte "Gültigkeit"',
          otherPricesHeading: 'Überschrift für den Bereich "Sonstige Entgelte"',
          priceConditionsHeading: 'Überschrift für den Bereich "Bedingungen für die Inanspruchnahme des Dienstes"',
          priceVolumeTableServiceHeading: 'Tabellenüberschrift für die Spalte "Leistung"',
          priceVolumeTableMeteringHeading: 'Tabellenüberschrift für die Spalte "Taktung"',
          priceVolumeTablePriceHeading: 'Tabellenüberschrift für die Spalte "Preis"',
          priceVolumeTable: {
            service: 'Leistung *',
            metering: 'Taktung',
            price: 'Preis *'
          },
          priceTableNote: 'Hinweistext für Preistabelle',
          priceTable: {
            invoiceLine: 'Posten *',
            price: 'Preis einmalig *',
            pricePerCycle: 'Preis pro Abrechnungszeitraum * | Preis pro Tag * | Preis pro {count} Tag(e) *',
            pricePerMonth: 'Preis pro Monat *',
            validity: 'Gültigkeit *'
          },
          priceTableRadio: {
            oneTimePrice: 'einmalig',
            recurringPrice: 'wiederkehrend (anderer Abrechnungszeitraum)',
            monthlyRecurringPrice: 'wiederkehrend (monatlicher Abrechnungszeitraum)'
          },
          otherPrices: 'Entgelt *',
          priceConditions: 'Bedingung *',
          hasPriceVolumeTable: 'Preise hinzufügen',
          hasOtherPrices: 'Sonstige Entgelte hinzufügen',
          hasPriceConditions: 'Bedingungen für die Inanspruchnahme des Dienstes zum angegebenen Preis hinzufügen'
        },
        buttons: {
          priceTable: {
            addButtonTitle: 'Preis hinzufügen',
            removeButtonTitle: 'Diesen Preis entfernen'
          },
          priceVolumeTable: {
            addButtonTitle: 'Preis hinzufügen',
            removeButtonTitle: 'Diesen Preis entfernen'
          },
          otherPrices: {
            addButtonTitle: 'Entgelt hinzufügen',
            removeButtonTitle: 'Dieses Entgelt entfernen'
          },
          priceConditions: {
            addButtonTitle: 'Bedingung hinzufügen',
            removeButtonTitle: 'Diese Bedingung entfernen'
          }
        }
      },
      periodSegment: {
        headings: {
          general: 'Laufzeit, Verlängerung und Kündigung',
          general_help: 'In diesem Segment sind die Vertragslaufzeit sowie die wichtigsten Bedingungen für die Vertragsverlängerung, Kündigung und vorzeitige Kündigung anzugeben. <ul> <li>Die Vertragslaufzeit ist in Monaten anzugeben. Achten Sie darauf, die passende Einheit mit anzugeben (z.B. 12 Monate). </li> <li> Ist der Vertrag auf unbestimmte Zeit geschlossen, muss das aus Ihren Angaben in dieser Vertragszusammenfassung hervorgehen.</li></ul>',
          contractTermRemedyTexts: 'Vertragsbedingungen',
          contractTermRemedyTexts_help: 'Unter diesem Punkt können Angaben zu den Vertrags- und Laufzeitbedingungen gemacht werden. Beispielsweise sollte hier angegeben werden, wenn sich die Mindestvertragslaufzeit aufgrund der Inanspruchnahme eines Sonderangebots verlängert.',
          terminationConditionTexts: 'Bedingungen zur Kündigung',
          terminationConditionTexts_help: 'Geben Sie hier die Kündigungsbedingungen für die reguläre Vertragsbeendigung zum Ende der Laufzeit an. <ul> <li> **Beispiel:** "Der Vertrag muss mit einer Frist von 3 Monate zum jeweiligen Ende der Laufzeit gekündigt werden.". </li> <li> Mit dem Plus-Button können Sie weitere Bedingungen hinzufügen.</li></ul>',
          contractExtensionTexts: 'Bedingungen zur Vertragsverlängerung',
          contractExtensionTexts_help: 'Geben Sie hier die Bedingungen an, unter denen der Vertrag (automatisch) verlängert wird. Beispielsweise durch Aufladung bei Prepaid-Tarifen oder automatische Verlängerung nach Ablauf der Mindestvertragslaufzeit, etc. <ul> <li> **Beispiel:** "Der Vertrag verlängert sich nach Ablauf der jeweiligen Vertragslaufzeit automatisch um weitere 12 Monate, wenn keine fristgerechte Kündigung erfolgt.". </li> <li> Mit dem Plus-Button können Sie weitere Bedingungen hinzufügen.</li></ul>',
          earlyTerminationTexts: 'Bedingungen zur vorzeitigen Kündigung',
          earlyTerminationTexts_help: 'Geben Sie hier die Bedingungen nach Unionsrecht und nationalem Recht an, unter denen der Kunde einen Anspruch auf eine vorzeitige Kündigung hat. Geben Sie hier auch die (Rest-)Entgelte an, die ggf. bei einer vorzeitigen Kündigung für den Kunden entstehen könnten. <ul> <li> **Beispiel:** "Vor Ablauf der Mindestvertragsdauer kann der Vertrag nur aus einem wichtigen Grund (z.B. bei bestimmten schwerwiegenden Störungen bzw. Mängeln) beendet werden (außerordentliche Kündigung). Bitte beachten Sie, dass folgende Kosten anfallen, wenn Sie sich dazu entscheiden, das Smartphone zu behalten: XX €" </li> <li> Mit dem Plus-Button können Sie weitere Bedingungen hinzufügen.</li></ul>',
          deviceUnlockTexts: 'Bedingungen zur Entsperrung der Geräte',
          deviceUnlockTexts_help: 'Geben Sie hier die Kosten für die Entsperrung von Endgeräten an, sofern diese in ihrer Nutzung beschränkt sind. <p> **Beispiel:** "Die Nutzung Ihres Endgeräts ist auf unser XX-Netz beschränkt. Die Gebühr für die Entsperrung Ihres Endgerätes beträgt XX €."</p>',
          addContractTermRemedyTexts: 'Vertragsbedingungen hinzufügen',
          addTerminationConditionTexts: 'Bedingungen zur Kündigung hinzufügen',
          addContractExtensionTexts: 'Bedingungen zur Vertragsverlängerung hinzufügen',
          addEarlyTerminationTexts: 'Bedingungen zur vorzeitigen Kündigung hinzufügen',
          addDeviceUnlockTexts: 'Bedingungen zur Entsperrung der Geräte hinzufügen'
        },
        labels: {
          heading: 'Überschrift für den Bereich "Laufzeit, Verlängerung und Kündigung"',
          contractTermText: 'Angezeigter Text vor der Mindestvertragslaufzeit',
          contractTerm: 'Mindestvertragslaufzeit *',
          contractTermRemedyTexts: 'Bedingung *',
          terminationConditionText: 'Überschrift für den Bereich "Kündigungsbedingungen"',
          terminationConditionTexts: 'Bedingung *',
          contractExtensionText: 'Überschrift für den Bereich "Vertragsverlängerung"',
          contractExtensionTexts: 'Bedingung *',
          earlyTerminationText: 'Überschrift für den Bereich "vorzeitige Kündigung"',
          earlyTerminationTexts: 'Bedingung *',
          deviceUnlockText: 'Überschrift für den Bereich "Entsperrung Ihrer Geräte"',
          deviceUnlockTexts: 'Bedingung *',
          hasContractTermRemedyTexts: 'Vertragsbedingungen angeben',
          hasTerminationConditionTexts: 'Bedingungen zur Kündigung hinzufügen',
          hasContractExtensionTexts: 'Bedingungen zur Vertragsverlängerung hinzufügen',
          hasEarlyTerminationTexts: 'Bedingungen zur vorzeitigen Kündigung hinzufügen',
          hasDeviceUnlockTexts: 'Bedingungen zur Entsperrung der Geräte hinzufügen'
        },
        buttons: {
          contractTermRemedyTexts: {
            addButtonTitle: 'Bedingung hinzufügen',
            removeButtonTitle: 'Diese Bedingung entfernen'
          },
          terminationConditionTexts: {
            addButtonTitle: 'Bedingung hinzufügen',
            removeButtonTitle: 'Diese Bedingung entfernen'
          },
          contractExtensionTexts: {
            addButtonTitle: 'Bedingung hinzufügen',
            removeButtonTitle: 'Diese Bedingung entfernen'
          },
          earlyTerminationTexts: {
            addButtonTitle: 'Bedingung hinzufügen',
            removeButtonTitle: 'Diese Bedingung entfernen'
          },
          deviceUnlockTexts: {
            addButtonTitle: 'Bedingung hinzufügen',
            removeButtonTitle: 'Diese Bedingung entfernen'
          }
        }
      },
      disabilitySegment: {
        headings: {
          general: 'Funktionsmerkmale für Endnutzer mit Behinderungen',
          general_help: 'Geben Sie hier Leistungsmerkmale Ihres Produktes an, die speziell für Endnutzer mit Behinderungen vorgesehen sind. <p> **Beispiele:** </p> <ul> <li> Echtzeittext </li> <li> Relay-Dienste </li> <li> barrierefrei zugängliche Notrufe </li> <li> Spezialausrüstung </li> <li> Sondertarife </li> <li> barrierefrei zugängliche Informationen </li> <li> etc.</li></ul> <br /> <p>Aufgrund des Umfangs der Informationen haben Sie die Möglichkeit, auf ein separates Dokument zu verweisen und dieses mit den weiteren Vorvertraglichen Informationen an den Kunden zu senden.</p> <p> Wenn Sie einen Link anzeigen möchten, haben Sie die Möglichkeit, diesen im folgenden Format in den Textfeldern des Formulars einzufügen: ```[Text](https://www.google.com)```</p> <p>Wenn Sie dieses Segment nicht ausfüllen, da Ihr Tarif keine speziellen Funktionsmerkmale für Endnutzer mit Behinderungen enthält, wird das Segment automatisch mit "Nicht zutreffend" gekennzeichnet.</p>'
        },
        labels: {
          heading: 'Überschrift für den Bereich "Funktionsmerkmale für Endnutzer mit Behinderungen"',
          placeholder: 'Anzeigetext, falls keine Angaben gemacht wurden',
          texts: 'Textzeile *',
          hasTexts: 'Funktionsmerkmale angeben'
        },
        buttons: {
          texts: {
            addButtonTitle: 'Textzeile hinzufügen',
            removeButtonTitle: 'Diese Textzeile entfernen'
          }
        }
      },
      otherSegment: {
        headings: {
          general: 'Sonstige Angaben',
          general_help: 'Geben Sie hier etwaige zusätzliche Informationen an, die nach Unionsrecht oder nationalem Recht erforderlich sind, damit der Verbraucher einen verbindlichen Vertrag abschließen kann. Hierunter fallen u.a. Informationen über: <ul> <li> Anbieterwechsel </li> <li> Sicherheit </li> <li> Umgang mit personenbezogenen Daten </li> <li> Angaben zu Zahlungsmodalitäten </li> <li> etc. </li></ul> <br />Darüber hinaus wird folgender Satz empfohlen: "Im Rahmen des Vertragsabschlusses sind für Sie günstiger abweichende Vereinbarungen von dieser Vertragszusammenfassung möglich." <p>Wenn Sie einen Link anzeigen möchten, haben Sie die Möglichkeit, diesen im folgenden Format in den Textfeldern des Formulars einzufügen: ```[Text](https://www.google.com)```</p>'
        },
        labels: {
          texts: 'Textzeile *',
          heading: 'Überschrift für den Bereich "Sonstige Angaben"',
          hasTexts: 'Sonstige Angaben machen'
        },
        buttons: {
          texts: {
            addButtonTitle: 'Textzeile hinzufügen',
            removeButtonTitle: 'Diese Textzeile entfernen'
          }
        }
      },
      submitSegment: {
        headings: {
          general: 'Daten überprüfen',
          preview: 'Vorschau'
        },
        labels: {
          templateName: 'Name für die VZF Vorlage *'
        },
        texts: {
          description: 'Bitte überprüfen Sie Ihre Daten.'
        },
        buttons: {
          previewEditButton: 'Bearbeiten'
        }
      },
      defaultValues: {
        bandwidthSegment: {
          remedyText: 'Wenn die oben angeführte Geschwindigkeit kontinuierlich oder regelmäßig wiederkehrend unterschritten wird, können Ihnen Gewährleistungsrechte zustehen. Sollte eine Verbesserung der Leistung nicht möglich sein (z.B. durch Tausch des Modems), können weitere Rechtsbehelfe (Preisminderung, Auflösung des Vertrages) zur Anwendung kommen.'
        },
        priceSegment: {
          pricePerCycleHeading: 'pro Abrechnungszeitraum | pro Tag | pro {count} Tag(e)'
        },
        baseSegment: {
          mainFont: 'Open Sans'
        }
      },
      updateDialog: {
        title: 'VZF-Vorlage überschreiben',
        text: 'Bitte beachten Sie, dass Ihre Änderungen für alle zukünftigen Anfragen gelten, für die dieses Template ausgewählt wurde.',
        buttons: {
          cancel: 'Abbrechen',
          confirm: 'Änderungen speichern'
        }
      }
    },
    dialogs: {
      buttons: {
        cancel: 'Abbrechen',
        confirm: 'Bestätigen'
      }
    },
    dateTimeType: {
      tabs: {
        date: 'Datum',
        time: 'Zeit'
      },
      buttons: {
        cancel: 'Abbrechen',
        submit: 'Übernehmen'
      }
    },
    fileUploader: {
      text: 'Legen Sie eine Datei hier ab oder <span class="text-decoration-underline blue--text">wählen Sie manuell eine aus</span>.',
      buttonText: 'Datei hochladen',
      errors: {
        format: 'Das Dateiformat ist nicht erlaubt',
        size: 'Die Datei ist zu groß. Erlaubt sind maximal {size} kB',
        generic: 'Beim Upload der Datei ist ein Problem aufgetreten'
      }
    }
  },
  units: {
    days: 'Tag(e)',
    timeString: 'Uhr'
  },
  api: {
    errorMessages: {
      generic: 'Bei der Verarbeitung der Anfrage ist es zu einem Problem gekommen.',
      typeError: {
        uuid: 'Dieser Wert muss eine gültige UUID sein'
      },
      valueError: {
        datetime: 'Das Datum ist nicht valide',
        email: 'Dieser Wert muss eine gültige E-Mail-Adresse sein',
        missing: 'Dieses Feld muss ausgefüllt sein',
        anyStr: {
          maxLength: 'Dieses Feld darf höchstens {limit_value} Zeichen enthalten.'
        }
      },
      keycloak: {
        'User not found': 'Benutzer wurde nicht gefunden',
        'User exists with same username': 'Es existiert bereits ein Benutzer mit diesem Benutzernamen',
        'User exists with same email': 'Es existiert bereits ein Benutzer mit dieser E-Mail-Adresse',
        'User exists with same username or email': 'Es existiert bereits ein Benutzer mit diesem Benutzernamen oder dieser E-Mail-Adresse'
      },
      contractSummaries: {
        'Unprocessable Entity': 'Fehler bei der Verarbeitung',
        'value_error.list.min_items': {
          1: 'Es muss mindestens ein Eintrag angegeben werden.',
          default: 'Es müssen mindestens {number} Einträge angegeben werden.'
        },
        'value_error.list.max_items': {
          1: 'Es darf höchstens ein Eintrag angegeben werden.',
          default: 'Es dürfen höchstens {number} Einträge angegeben werden.'
        },
        'value_error.any_str.max_length': {
          1: 'Dieses Feld darf höchstens ein Zeichen enthalten.',
          default: 'Dieses Feld darf höchstens {number} Zeichen enthalten.'
        },
        'value_error.missing': 'Dies ist ein Pflichtfeld.+'
      },
      mailTemplates: {
        create: 'Die E-Mail-Vorlage konnte nicht erstellt werden.',
        delete: 'Die E-Mail-Vorlage konnte nicht gelöscht werden.',
        fetch: 'Die E-Mail-Vorlagen konnten nicht geladen werden.',
        preview: 'Die Vorschau konnte nicht gesendet werden.',
        update: 'Die E-Mail-Vorlage konnte nicht gespeichert werden.'
      },
      markdown: {
        'Unprocessable Entity': 'Fehler bei der Verarbeitung'
      }
    },
    successMessages: {
      mailTemplates: {
        create: 'Die E-Mail-Vorlage wurde gespeichert.',
        delete: 'Die E-Mail-Vorlage wurde gelöscht.',
        preview: 'Vorschau wurde erfolgreich versendet.',
        update: 'Die E-Mail-Vorlage wurde gespeichert.'
      }
    }
  },
  form: {
    errorMessages: {
      invalid: 'Das Formular ist nicht valide',
      generic: 'Bei der Anfrage ist etwas schief gelaufen. Bitte versuchen Sie es später erneut',
      fields: {
        generic: {
          required: 'Dieses Feld ist ein Pflichtfeld',
          requiredField: 'Dieses Feld ist ein Pflichtfeld, wenn "{field}" ausgefüllt ist',
          collection: 'Ungültiger Wert'
        },
        date: {
          min: 'Dieses Datum darf nicht vor dem {date} liegen',
          max: 'Dieses Datum darf nicht nach dem {date} liegen',
          minField: 'Dieses Datum darf nicht vor dem Datum in "{field}" liegen',
          maxField: 'Dieses Datum darf nicht nach dem Datum in "{field}" liegen'
        },
        number: {
          min: 'Diese Zahl muss mindestens {number} sein',
          max: 'Diese Zahl darf maximal {number} sein'
        },
        text: {
          min: 'Dieser Wert muss mindestens {length} Zeichen haben',
          max: 'Dieser Wert darf maximal {length} Zeichen haben',
          noFileExt: 'Dieser Wert darf keine Dateiendung enthalten'
        },
        types: {
          email: 'Dieser Wert muss eine gültige E-Mail-Adresse sein',
          numeric: 'Dieser Wert muss numerisch sein',
          uuid: 'Dieser Wert muss eine gültige UUID sein'
        }
      }
    }
  }
}

export default global
