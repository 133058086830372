const help = {
  meta: {
    title: 'Hilfe und Support',
    heading: 'Hilfe und Support',
    subHeading: ''
  },
  components: {
    management: {
      dialogs: {
        edit: {
          title: 'Seite bearbeiten',
          buttons: {
            update: 'Speichern'
          },
          easyMde: {
            buttons: {
              bold: 'Fett',
              italic: 'Kursiv',
              heading1: 'Überschrift 1',
              heading2: 'Überschrift 2',
              heading3: 'Überschrift 3',
              quote: 'Zitat',
              unorderedList: 'Aufzählung',
              orderedList: 'Nummerierte Liste',
              link: 'Link erstellen',
              preview: 'Vorschau',
              sideBySide: 'Editor + Vorschau',
              fullScreen: 'Vollbild'
            }
          }
        }
      },
      buttons: {
        edit: 'Hilfeseite bearbeiten'
      },
      messages: {
        success: 'Erfolgreich gespeichert'
      }
    }
  }
}

export default help
