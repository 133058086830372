<template>
  <div class="errors">
    <div
      v-for="(error) in errorsShowing"
      :key="`error-${error.originalIndex}`"
      class="errors__error red mb-4 px-4 py-4 d-flex flex-row justify-space-between align-center"
    >
      <span>
        {{ error.error.errorMessage }}
      </span>
      <v-btn
        icon
        @click="hideError(error.originalIndex)"
      >
        <v-icon
          small
          color="white"
        >
          {{ $icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NotificationState from '@/types/NotificationState'

export default {
  name: 'ErrorDisplay',
  methods: {
    ...mapActions('errors', ['showError', 'hideError'])
  },
  computed: {
    ...mapGetters('errors', ['errors']),
    errorsShowing () {
      return this.errors.map((err, i) => {
        err.originalIndex = i
        return err
      }).filter((err) => {
        return err.notificationState === NotificationState.SHOWING
      })
    }
  },
  watch: {
    errors (newValue) {
      newValue.forEach((err, i) => {
        if (err.notificationState === NotificationState.NEW) {
          this.showError(i)
        }
      })
    }
  }
}
</script>

<style scoped>
  .errors {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10000;
  }
  .errors__error {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    color: #fff;
    border-radius: 0.5rem;
  }
</style>
