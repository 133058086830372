import { getStatistics } from '@/api/statistics.api'

const state = {
  loading: false,
  statistics: {}
}

const getters = {
  loading (state) {
    return state.loading
  },
  statistics (state) {
    return state.statistics
  }
}

const actions = {
  async fetchStatistics ({ commit }) {
    try {
      commit('SET_LOADING', true)

      const response = await getStatistics()

      commit('SET_STATISTICS', response.data)
    } catch (e) {
      console.error(e.message)
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

const mutations = {
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_STATISTICS (state, statistics) {
    state.statistics = statistics
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
