import Message from '@/types/Message'

const state = {
  messages: []
}

const getters = {
  messages (state) {
    return state.messages
  },
  firstMessage (state) {
    return state.messages[0] ?? null
  },
  queueLength (state) {
    return state.messages.length
  },
  hasQueue (state) {
    return state.messages.length > 0
  }
}

const actions = {
  queueError ({ commit }, message) {
    commit('QUEUE_MESSAGE', { type: Message.ERROR, message })
  },
  queueInfo ({ commit }, message) {
    commit('QUEUE_MESSAGE', { type: Message.INFO, message })
  },
  queueSuccess ({ commit }, message) {
    commit('QUEUE_MESSAGE', { type: Message.SUCCESS, message })
  },
  queueWarning ({ commit }, message) {
    commit('QUEUE_MESSAGE', { type: Message.WARNING, message })
  },
  getNextMessage ({ commit, getters }) {
    if (!getters.hasQueue) {
      return null
    }

    const message = getters.firstMessage

    commit('SHIFT_MESSAGE')

    return message
  }
}

const mutations = {
  QUEUE_MESSAGE (state, { type, message }) {
    state.messages.push({ type, message })
  },
  SHIFT_MESSAGE (state) {
    state.messages.shift()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
