import NotificationState from '@/types/NotificationState'

const state = {
  errors: []
}

const getters = {
  errors (state) {
    return state.errors
  }
}

const actions = {
  addError ({ commit }, error) {
    commit('ADD_ERROR', { ...error, notificationState: NotificationState.NEW })
  },
  showError ({ commit, dispatch }, index) {
    commit('SHOW_ERROR', index)
    setTimeout(() => {
      dispatch('hideError', index)
    }, 3000)
  },
  hideError ({ commit }, index) {
    commit('HIDE_ERROR', index)
  }
}

const mutations = {
  ADD_ERROR (state, error) {
    state.errors.push(error)
  },
  SHOW_ERROR (state, index) {
    state.errors = state.errors.map((err, i) => {
      if (i === index) {
        err.notificationState = NotificationState.SHOWING
      }
      return err
    })
  },
  HIDE_ERROR (state, index) {
    state.errors = state.errors.map((err, i) => {
      if (i === index) {
        err.notificationState = NotificationState.SHOWN
      }
      return err
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
