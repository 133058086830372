import camelCase from 'lodash/camelCase'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import snakeCase from 'lodash/snakeCase'
import Vue from 'vue'

import store from '../store'
import VueI18n from '../i18n'

const blackList = [
  'createdOn',
  'createdBy',
  'createdUser',
  'updatedOn',
  'updatedBy',
  'updatedUser',
  'deletedOn',
  'deletedBy',
  'deletedUser'
]

const transformObjectKeys = (obj, callback) => {
  if (isArray(obj)) {
    const _objCopy = [...obj]
    return _objCopy.map((_obj) => transformObjectKeys(_obj, callback))
  }

  if (isObject(obj)) {
    const _obj = {}

    Object.keys(obj).forEach((key) => {
      if (isObject(obj[key])) {
        _obj[callback(key)] = transformObjectKeys(obj[key], callback)
        return
      }

      _obj[callback(key)] = obj[key]
    })

    return _obj
  }

  return obj
}

const normalizeRequest = (request) => {
  const data = request.data
  if (data) {
    blackList.forEach((key) => {
      delete data[key]
    })
    request.data = transformObjectKeys(data, (str) => snakeCase(str).replaceAll(/_(\d)/g, '$1'))
  }

  return request
}

const normalizeResponse = (response) => {
  const data = response.data
  if (data) {
    response.data = transformObjectKeys(data, camelCase)
  }

  return response
}

const handleSuccess = async (response, handlers) => {
  if (response.status < 200 || response.status >= 300) {
    return
  }

  const status = `${response.status}`
  const genericSuccessStatus = '2xx'

  let successHandler = handlers[status]

  if (!successHandler) {
    successHandler = handlers[genericSuccessStatus]
  }

  if (!isFunction(successHandler)) {
    return
  }

  const message = await successHandler(response)

  if (!message) {
    return
  }

  await store.dispatch('messageQueue/queueSuccess', message)
}

const handleError = async (response, handlers = {}, errorMessage = '') => {
  if (errorMessage) {
    Vue.cError(errorMessage, response)
  }

  if (!response) {
    return
  }

  if (response.status < 400 || response.status >= 600) {
    return
  }

  const status = `${response.status}`

  let genericErrorStatus = '4xx'

  if (status >= 500) {
    genericErrorStatus = '5xx'
  }

  let errorHandler = handlers[status]

  if (!errorHandler) {
    errorHandler = handlers[genericErrorStatus]
  }

  let message

  if (isFunction(errorHandler)) {
    message = await errorHandler(response)
  } else {
    message = response?.data?.detail ?? VueI18n.t('global.api.errorMessages.generic')
  }

  if (!message) {
    return
  }

  await store.dispatch('messageQueue/queueError', message)
}

const getHttpValidationErrorDetails = (response) => {
  const { data, status } = response

  if (status !== 422 || !data.detail || !isArray(data.detail)) {
    return null
  }

  return data.detail.map((validationError) => {
    if (!validationError.loc || !validationError.msg || !validationError.type) {
      return null
    }

    if (!isArray(validationError.loc) || validationError.loc.length < 2 || validationError.loc[0] !== 'body') {
      return null
    }

    return Object.keys(validationError).reduce((transformedError, key) => {
      if (key === 'loc') {
        // forms use a flat structure, so the last part of the location path is used
        transformedError.field = camelCase(validationError.loc.at(-1))
      } else if (key === 'type') {
        const context = validationError?.ctx ?? {}
        const errorKey = validationError.type.split('.').map(camelCase).join('.')
        transformedError.message = VueI18n.t(`global.api.errorMessages.${errorKey}`, context)
        transformedError[key] = camelCase(validationError[key])
      }

      return transformedError
    }, {})
  })
}

const getHttpBadRequestErrorDetails = (response) => {
  const { data, status } = response

  if (status !== 400 || !data.detail || !isString(data.detail)) {
    return null
  }

  return data.detail
}

export { normalizeRequest, normalizeResponse, transformObjectKeys, handleSuccess, handleError, getHttpValidationErrorDetails, getHttpBadRequestErrorDetails }
