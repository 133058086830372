import { isPast } from 'date-fns'
import findLast from 'lodash/findLast'

import {
  getDocumentHandles,
  createDocumentHandle,
  createDocumentVersion,
  getDocumentHandle,
  updateDocumentHandle,
  deleteDocumentHandle,
  deleteDocumentVersion
} from '@/api/documents.api'

import { handleError, handleSuccess } from '@/helpers/api.helper'
import { createValidDateFromApi } from '@/helpers/datetime.helper'

const state = {
  documents: [],
  activeDocument: null,
  loading: false,
  creatingHandle: false,
  documentsSidePanel: false
}

const getters = {
  documents (state) {
    return state.documents
  },
  activeDocument (state) {
    return state.activeDocument
  },
  activeDocumentUuid (state) {
    return state.activeDocument?.uuid
  },
  activeDocumentVersion (state) {
    return findLast(state.activeDocument?.documentVersions ?? [], ({ validFrom }) => isPast(createValidDateFromApi(validFrom)))
  },
  activeDocumentVersions (state) {
    return state.activeDocument?.documentVersions ?? []
  },
  activeDocumentComments (state) {
    const versions = state.activeDocument?.documentVersions ?? []

    const comments = versions
      .filter(({ comment }) => comment.length > 0)
      .map(({ comment, createdUser, createdOn }) => ({ comment, createdUser, createdOn }))
      .reverse()

    // if (state.activeDocument.comment.length > 0) {
    //   comments.push({
    //     comment: state.activeDocument.comment,
    //     createdUser: state.activeDocument.createdUser,
    //     createdOn: state.activeDocument.createdOn
    //   })
    // }

    return comments
  },
  loading (state) {
    return state.loading
  },
  creatingHandle (state) {
    return state.creatingHandle
  }
}

const actions = {
  async getDocumentHandles ({ commit, dispatch }, { handlers = {} }) {
    try {
      commit('LOADING', true)

      const response = await getDocumentHandles()
      const documents = response.data
      await handleSuccess(response, handlers)

      await dispatch('setDocuments', documents)
    } catch (e) {
      await handleError(e.response, handlers, e.message)
    } finally {
      commit('LOADING', false)
    }
  },
  async createDocument ({ commit, dispatch }, { name, comment = '', filename = null, handlers = {} }) {
    try {
      commit('CREATING_HANDLE', true)

      const response = await createDocumentHandle(name, comment, filename)
      const uuid = response.data.uuid
      await dispatch('loadDocument', { uuid })
      dispatch('setDocumentsSidePanel', true)
      await handleSuccess(response, handlers)

      await dispatch('getDocumentHandles', {})
    } catch (e) {
      await handleError(e.response, handlers, e.message)
    } finally {
      commit('CREATING_HANDLE', false)
    }
  },
  async updateDocument ({ commit, dispatch }, { uuid, data, handlers = {} }) {
    try {
      commit('LOADING', true)
      const response = await updateDocumentHandle(uuid, data)
      await handleSuccess(response, handlers)

      await dispatch('getDocumentHandles', {})
      await dispatch('loadDocument', { uuid })
      return true
    } catch (e) {
      await handleError(e.response, handlers, e.message)
      return false
    } finally {
      commit('LOADING', false)
    }
  },
  async createDocumentVersion ({ commit, dispatch }, { uuid, comment, fileUuid, validFrom, validTo, handlers = {} }) {
    try {
      commit('LOADING', true)

      const response = await createDocumentVersion({
        uuid,
        comment,
        fileUuid,
        validFrom,
        validTo
      })
      await handleSuccess(response, handlers)

      await dispatch('getDocumentHandles', {})
      await dispatch('loadDocument', { uuid })
    } catch (e) {
      await handleError(e.response, handlers, e.message)
    } finally {
      commit('LOADING', false)
    }
  },
  async deleteDocument ({ commit, dispatch }, { uuid, handlers = {} }) {
    try {
      commit('CREATING_HANDLE', true)

      const response = await deleteDocumentHandle(uuid)
      await handleSuccess(response, handlers)

      await dispatch('getDocumentHandles', {})
    } catch (e) {
      await handleError(e.response, handlers, e.message)
    } finally {
      commit('CREATING_HANDLE', false)
    }
  },
  async deleteDocumentVersion ({ commit, dispatch }, { uuid, versionUuid, handlers = {} }) {
    try {
      commit('LOADING', true)

      const response = await deleteDocumentVersion(uuid, versionUuid)
      await handleSuccess(response, handlers)

      await dispatch('getDocumentHandles', {})
      await dispatch('loadDocument', { uuid })
    } catch (e) {
      await handleError(e.response, handlers, e.message)
    } finally {
      commit('LOADING', false)
    }
  },
  async loadDocument ({ commit, dispatch }, { uuid, handlers = {} }) {
    try {
      commit('LOADING', true)

      commit('ACTIVE_DOCUMENT', await dispatch('getDocument', { uuid }))
    } catch (e) {
      await handleError(e.response, handlers, e.message)
    } finally {
      commit('LOADING', false)
    }
  },
  resetDocument ({ commit }) {
    commit('ACTIVE_DOCUMENT', null)
  },
  async getDocument ({ commit, state }, { uuid }) {
    let document = state.documents.find((document) => document.uuid === uuid)

    if (!document) {
      const { data: documentHandle } = await getDocumentHandle(uuid)
      commit('ADD_DOCUMENT', documentHandle)

      document = state.documents.find((document) => document.uuid === uuid)
    }

    return document
  },
  setDocuments ({ commit }, documents) {
    commit('DOCUMENTS', documents)
  },
  setDocumentsSidePanel ({ commit }, documentsSidePanel) {
    commit('SET_DOCUMENTS_SIDE_PANEL', documentsSidePanel)
  }
}

const mutations = {
  CLEAR_DOCUMENTS (state) {
    state.documents = []
  },
  DOCUMENTS (state, items) {
    state.documents = items
  },
  ADD_DOCUMENT (state, document) {
    state.documents.push(document)
  },
  ACTIVE_DOCUMENT (state, documentData) {
    state.activeDocument = documentData
  },
  LOADING (state, loading) {
    state.loading = !!loading
  },
  CREATING_HANDLE (state, creating) {
    state.creatingHandle = !!creating
  },
  SET_DOCUMENTS_SIDE_PANEL (state, documentsSidePanel) {
    state.documentsSidePanel = documentsSidePanel
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
