import {
  getMailTemplates,
  createMailTemplate,
  updateMailTemplate,
  previewMailTemplate,
  deleteMailTemplate
} from '@/api/mailTemplates.api'
import i18n from '@/i18n'

const state = {
  loading: false,
  mailTemplates: []
}

const getters = {
  loading (state) {
    return state.loading
  },
  mailTemplates (state) {
    return state.mailTemplates
  },
  total (state) {
    return state.mailTemplates.length
  },
  mailTemplate: (state) => (uuid) => state.mailTemplates.find((mailTemplate) => mailTemplate.uuid === uuid)
}

const actions = {
  async fetchMailTemplates ({ commit, dispatch }) {
    try {
      commit('LOADING', true)

      const response = await getMailTemplates()

      commit('MAIL_TEMPLATES', response.data)
    } catch (e) {
      const errorMessage = i18n.t('global.api.errorMessages.mailTemplates.fetch')
      dispatch('messageQueue/queueError', errorMessage, { root: true })
    } finally {
      commit('LOADING', false)
    }
  },
  async createMailTemplate ({ commit, dispatch }, { name, subject, htmlText, plainText, handlers = {} }) {
    try {
      commit('LOADING', true)

      await createMailTemplate({
        name,
        subject,
        htmlText,
        plainText
      })

      const message = i18n.t('global.api.successMessages.mailTemplates.create')
      dispatch('messageQueue/queueSuccess', message, { root: true })

      dispatch('fetchMailTemplates')
      return true
    } catch (e) {
      const errorMessage = i18n.t('global.api.errorMessages.mailTemplates.create')
      dispatch('messageQueue/queueError', errorMessage, { root: true })
      return false
    } finally {
      commit('LOADING', false)
    }
  },
  async updateMailTemplate ({ commit, dispatch }, { uuid, name, subject, htmlText, plainText, handlers = {} }) {
    try {
      commit('LOADING', true)

      await updateMailTemplate(uuid, {
        name,
        subject,
        htmlText,
        plainText
      })

      const message = i18n.t('global.api.successMessages.mailTemplates.update')
      dispatch('messageQueue/queueSuccess', message, { root: true })

      dispatch('fetchMailTemplates')
      return true
    } catch (e) {
      const errorMessage = i18n.t('global.api.errorMessages.mailTemplates.update')
      dispatch('messageQueue/queueError', errorMessage, { root: true })
      return false
    } finally {
      commit('LOADING', false)
    }
  },
  async previewMailTemplate ({ dispatch }, { uuid, email }) {
    try {
      await previewMailTemplate(uuid, email)
      const message = i18n.t('global.api.successMessages.mailTemplates.preview')
      dispatch('messageQueue/queueSuccess', message, { root: true })
      return true
    } catch (e) {
      const errorMessage = i18n.t('global.api.errorMessages.mailTemplates.preview')
      dispatch('messageQueue/queueError', errorMessage, { root: true })
      return false
    }
  },
  async deleteMailTemplate ({ commit, dispatch }, { uuid, handlers = {} }) {
    try {
      commit('LOADING', true)

      await deleteMailTemplate(uuid)
      const message = i18n.t('global.api.successMessages.mailTemplates.delete')
      dispatch('messageQueue/queueSuccess', message, { root: true })

      dispatch('fetchMailTemplates')
      return true
    } catch (e) {
      const errorMessage = i18n.t('global.api.errorMessages.mailTemplates.delete')
      dispatch('messageQueue/queueError', errorMessage, { root: true })
      return false
    } finally {
      commit('LOADING', false)
    }
  }
}

const mutations = {
  LOADING (state, loading) {
    state.loading = loading
  },
  MAIL_TEMPLATES (state, mailTemplates) {
    state.mailTemplates = mailTemplates
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
