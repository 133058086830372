import { createClient } from './httpClient'
import config from '@/config/config'
import { normalizeRequest, normalizeResponse } from '@/helpers/api.helper'

const endpoints = () => config.get('api.endpoints.markdownPages')

const httpClient = createClient()

httpClient.interceptors.request.use(normalizeRequest)
httpClient.interceptors.response.use(normalizeResponse)

const getMarkdownPageHandle = (markdownPageUuid) =>
  httpClient.get(endpoints().get('read', { markdownPageUuid }))

const updateMarkdownPageHandle = ({ markdownPageUuid, data }) =>
  httpClient.put(endpoints().get('update', { markdownPageUuid }), data)

export {
  getMarkdownPageHandle,
  updateMarkdownPageHandle
}
