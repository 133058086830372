const documentFolders = {
  meta: {
    title: 'Dokumentenmappen',
    heading: 'Dokumentenmappen',
    subHeading: 'Hier können Sie Dokumentenmappen für bestimmte Produkte oder Produktgruppen erstellen, einsehen und verwalten.'
  },
  buttons: {
    create: 'Neue Mappe erstellen'
  },
  components: {
    form: {
      heading: 'Dokumentenmappe erstellen',
      heading_help: 'Um eine Dokumentenmappe zu erstellen, legen Sie bitte einen (möglichst eindeutigen) Dokumentenmappennamen fest (z.B. DSL 5000, Mobilfunk-Verträge, etc.), um die Zugehörigkeit zu einem bestimmten Produkt oder einer Produktgruppe anzuzeigen. Optional können Sie einen Kommentar zu der Dokumentenmappe hinzufügen. <p> Klicken Sie auf "Dokumentenmappe erstellen". Die Mappe erscheint unter "Dokumentenmappen verwalten". Hier können Sie im nächsten Schritt Dokumente zu der Mappe hinzufügen. </p>',
      fields: {
        name: {
          label: 'Name *',
          placeholder: 'Name'
        },
        comment: {
          label: 'Kommentar',
          placeholder: 'Kommentar'
        },
        documentUuids: {
          label: 'Dokumente *',
          placeholder: 'Dokumente'
        }
      },
      success: 'Dokumentenmappe wurde erfolgreich erstellt',
      buttons: {
        submit: 'Dokumentenmappe erstellen'
      }
    },
    management: {
      heading: 'Dokumentenmappen verwalten',
      heading_help: {
        default: 'In der Tabelle finden Sie eine Übersicht über alle bereits erstellten Dokumentenmappen. In der Detail-Ansicht sehen Sie die aktuelle Zusammensetzung der Dokumentenmappe. <p> Sie haben die Möglichkeit vorhandene Dokumentenmappen zu bearbeiten und zu löschen ("Löschen" nicht empfohlen).</p>  <ul> <li>Beachten Sie, dass bereits aktive Dokumente nicht mehr aus der Mappe gelöscht werden können. </li> <li> Achten Sie zudem darauf, dass das Löschen von Dokumentenmappen dazu führt, dass die Mappe nicht mehr versendet werden kann. Das kann ggf. auch Auswirkungen auf Ihre Third Party Integration haben.</li></ul>',
        clerk: 'In der Tabelle finden Sie eine Übersicht über alle bereits erstellten Dokumentenmappen. In der Detail-Ansicht sehen Sie die aktuelle Zusammensetzung der Dokumentenmappe. <p>Bitte beachten Sie, dass Änderungen nur von Nutzern mit den Rollen "Administrator" oder "Manager" vorgenommen werden können. Falls Sie Änderungen vornehmen oder neue Mappen erstellen möchten, wenden Sie sich bitte an Ihren zuständigen Administrator.</p>'
      },
      commentHeading: 'Kommentar:',
      table: {
        headers: {
          name: 'Name',
          createdOn: 'Erstellt',
          updatedOn: 'Aktualisiert',
          uuid: 'Dokumentenmappen-ID'
        },
        search: {
          label: 'Dokumentenmappensuche',
          hint: 'Suche nach Dokumentenmappen'
        },
        dialogs: {
          delete: {
            title: 'Dokumentenmappe "{folder}" löschen?',
            buttons: {
              cancel: 'Abbrechen',
              confirm: 'Löschen'
            }
          }
        },
        tooltips: {
          noDocuments: 'Keine Dokumente vorhanden'
        },
        actions: {
          delete: 'Dokumentenmappe löschen',
          messages: {
            delete: {
              success: 'Die Dokumentenmappe wurde erfolgreich gelöscht',
              error: 'Die Dokumentenmappe konnte nicht gelöscht werden'
            }
          }
        }
      }
    },
    memberships: {
      add: 'Dokument hinzufügen',
      form: {
        title: 'Dokument hinzufügen',
        title_help: 'Wählen Sie das Dokument aus, welches Sie zur Mappe hinzufügen wollen. Die Dokumente müssen zuvor unter dem entsprechenden Menü-Punkt "Dokumente" hochgeladen worden sein. Legen Sie das Start- und optional das Enddatum für das Dokument fest, um zu definieren, in welchem Zeitraum das Dokument in der Mappe enthalten sein und an den Kunden versendet werden soll. <p>Optional können Sie einen Kommentar zu dem Dokument hinzufügen.</p> <p>Beachten Sie, dass je nach Anzahl und Größe der Dokumente in der Dokumentenmappe, die maximal erlaubte Größe Ihrer Dateianhänge überschritten werden könnte. Der Versand der E-Mail an bestimmte Mail-Clients ist ggf. nicht mehr möglich.</p>',
        titleEdit: 'Dokument bearbeiten',
        titleEdit_help: ' Hier können Sie Dokumente in der Mappe bearbeiten. Beachten Sie, dass Sie das Startdatum nur dann bearbeiten können, wenn dieses in der Zukunft liegt.',
        fields: {
          document: {
            label: 'Dokument *',
            placeholder: 'Dokument'
          },
          validFrom: {
            label: 'Gültig von *',
            placeholder: 'Gültig von'
          },
          validTo: {
            label: 'Gültig bis',
            placeholder: 'Gültig bis'
          },
          comment: {
            label: 'Kommentar',
            placeholder: 'Kommentar'
          }
        },
        buttons: {
          cancel: 'Abbrechen',
          edit: 'Dokument bearbeiten',
          submit: 'Dokument hinzufügen'
        },
        messages: {
          success: 'Dokument erfolgreich hinzugefügt',
          successEditing: 'Dokument erfolgreich bearbeitet',
          invalid: 'Das Formular ist nicht valide. Gehen Sie sicher, dass es keine Überschneidung der Zeiträume gibt.'
        }
      },
      table: {
        title: 'Dokumente',
        title_help: {
          default: 'Hier sehen Sie alle Dokumente, die derzeit in der Dokumentenmappe vorhanden sind. <ul> <li> Beachten Sie, dass Dokumente mehrmals für verschiedene Zeiträume in der Mappe auftauchen können. Aktuell aktive Dokumente sind markiert. </li> <li> Klicken Sie auf "Dokument hinzufügen", um die Mappe zu erweitern. </li> <li> Beachten Sie, dass aktive Dokumente nicht mehr aus der Mappe gelöscht werden können. </li> <li> Sie können sich hier auch die Zusammensetzung der Mappe zu einem bestimmten Zeitpunkt anzeigen lassen. Wählen Sie dafür das gewünschte Datum in dem entsprechenden Eingabefeld aus.</li></ul>',
          clerk: 'Hier sehen Sie alle Dokumente, die derzeit in der Dokumentenmappe vorhanden sind. <ul> <li> Beachten Sie, dass Dokumente mehrmals für verschiedene Zeiträume in der Mappe auftauchen können. Aktuell aktive Dokumente sind markiert. </li> <li> Sie können sich hier auch die Zusammensetzung der Mappe zu einem bestimmten Zeitpunkt anzeigen lassen. Wählen Sie dafür das gewünschte Datum in dem entsprechenden Eingabefeld aus.</li> </ul> \nBitte beachten Sie, dass Änderungen nur von Nutzern mit den Rollen "Administrator" oder "Manager" vorgenommen werden können. Falls Sie Änderungen vornehmen oder neue Dokumente hinzufügen möchten, wenden Sie sich bitte an Ihren zuständigen Administrator.'
        },
        showAtDate: {
          label: 'Mappe zum Zeitpunkt anzeigen',
          placeholder: 'Zeitpunkt'
        },
        headers: {
          name: 'Dokument',
          documentHandleUuid: 'Dokumenten-UUID',
          currentDocumentVersion: 'Zum Zeitpunkt gültige Dokumentenversion',
          validFrom: 'Gültig von',
          validTo: 'Gültig bis',
          comment: 'Kommentar'
        },
        dialogs: {
          delete: {
            title: 'Dokument aus der Mappe entfernen',
            text: 'Möchten Sie das Dokument wirklich aus der Dokumentenmappe entfernen?',
            buttons: {
              cancel: 'Abbrechen',
              confirm: 'Löschen'
            }
          }
        },
        actions: {
          edit: 'Dokument bearbeiten',
          delete: 'Dokument entfernen',
          messages: {
            delete: {
              success: 'Das Dokument wurde erfolgreich aus der Mappe entfernt',
              error: 'Das Dokument konnte nicht aus der Mappe entfernt werden. Möglicherweise liegt der Gültigkeitsbeginn in der Vergangenheit',
              errorGeneric: 'Das Dokument konnte nicht aus der Mappe entfernt werden'
            }
          }
        }
      }
    }
  }
}

export default documentFolders
