import { getFileUrl } from '@/api/files.api'
import { getSettings, putSettings } from '@/api/settings.api'

import { handleError, handleSuccess } from '@/helpers/api.helper'
import config from '@/config/config'

const state = {
  loading: false,
  initialLoad: false,
  settings: {}
}

const getters = {
  loading (state) {
    return state.loading
  },
  settings (state) {
    return state.settings
  },
  logo (state) {
    if (state.settings.companyLogoUuid) {
      return getFileUrl(state.settings.companyLogoUuid)
    }

    return null
  },
  periods (state) {
    return {
      deletionPeriod: state.settings?.deletionTermDays ?? 0,
      retentionPeriod: state.settings?.provisionTermDays ?? 0,
      validityPeriod: state.settings?.validityTermDays ?? 0
    }
  },
  colors (state) {
    return {
      primaryColor: state.settings?.primaryColor ?? '#000000',
      secondaryColor: state.settings?.secondaryColor ?? '#F0F0F0'
    }
  },
  fontSize (state) {
    return state.settings?.fontSize ?? 12
  },
  font (state) {
    return state.settings?.font ?? config.get('requests.templates.defaultFont')
  },
  initialLoad (state) {
    return state.initialLoad
  }
}

const actions = {
  async fetchSettings ({ commit }) {
    try {
      commit('LOADING', true)

      const response = await getSettings()

      commit('SETTINGS', response.data)
      commit('INITIAL_LOAD', true)
    } catch (e) {
      await handleError(e.response, {}, e.message)
    } finally {
      commit('LOADING', false)
    }
  },
  async updateSettings ({ commit, state }, { data, handlers }) {
    try {
      commit('LOADING', true)

      commit('SETTINGS', Object.assign({}, state.settings, data))

      const response = await putSettings(this.getters['settings/settings'])
      await handleSuccess(response, handlers)
    } catch (e) {
      await handleError(e.response, handlers, e.message)
    } finally {
      commit('LOADING', false)
    }
  }
}

const mutations = {
  LOADING (state, loading) {
    state.loading = loading
  },
  SETTINGS (state, settings) {
    state.settings = settings
  },
  INITIAL_LOAD (state, loaded) {
    state.initialLoad = loaded
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
