const editTemplate = {
  meta: {
    title: 'VZF-Vorlage bearbeiten',
    heading: 'VZF-Vorlage bearbeiten',
    subHeading: ''
  },
  components: {
    buttons: {
      backButton: {
        text: 'Zurück zur Vorlagenverwaltung'
      }
    },
    dialogs: {
      leaveRouteConfirmation: {
        title: 'Bearbeitung abbrechen',
        text: 'Möchten Sie die Bearbeitung der VZF-Vorlage abbrechen? Ihre Daten werden nicht gespeichert.',
        buttons: {
          cancel: 'Weiter bearbeiten',
          confirm: 'Bearbeitung abbrechen'
        }
      }
    }
  }
}

export default editTemplate
