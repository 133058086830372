const requests = {
  meta: {
    title: 'Anfragen',
    heading: 'Anfragen',
    subHeading: 'Hier finden Sie eine Übersicht aller Anfragen, die bereits für Ihre Kunden erstellt wurden. Eine Anfrage besteht dabei mindestens aus einer Vertragszusammenfassung und einer Dokumentenmappe mit weiteren vorvertraglichen Dokumenten sowie dem Kundennamen. In der Übersichtstabelle können Sie die Auftrags-ID und den Status der jeweiligen Anfrage einsehen.'
  },
  states: {
    accepted: 'Angenommen',
    canceled: 'Abgebrochen',
    declined: 'Abgelehnt',
    expired: 'Abgelaufen',
    open: 'Offen'
  },
  deletedFolder: '<span class="text-decoration-line-through">{folder}</span> (gelöscht)',
  components: {
    statistics: {
      periods: {
        label: 'Zeitraum',
        day: 'Letzte 24 Stunden',
        week: 'Letzte Woche',
        month: 'Letzter Monat'
      }
    },
    management: {
      actions: {
        create: 'Anfrage erstellen'
      },
      table: {
        headers: {
          uuid: 'UUID',
          customer: 'Kunde',
          surrogatCustomerUid: 'Referenznummer',
          createdOn: 'Angefragt am',
          processStatus: 'Status',
          documentFolderName: 'Dokumentenmappe',
          validity: 'Gültig bis'
        },
        footer: {
          itemsPerPage: 'Anfragen pro Seite'
        },
        actions: {
          details: {
            tooltip: 'Detailansicht öffnen'
          },
          resend: {
            tooltip: 'E-Mail erneut versenden',
            dialog: {
              title: 'E-Mail erneut versenden',
              text: 'E-Mail erneut an {name} ({email}) versenden?',
              actions: {
                cancel: 'Abbrechen',
                confirm: 'Versenden'
              },
              messages: {
                success: 'E-Mail erfolgreich verschickt'
              }
            }
          }
        },
        search: {
          label: 'Suche',
          hint: 'Suche nach ID oder Kunde'
        }
      }
    },
    details: {
      contractSummary: {
        title: 'Vertragszusammenfassung',
        productOverline: 'Produkt'
      },
      customer: {
        customerId: 'Referenznummer: {customerId}',
        businessCustomer: 'Geschäftskunde',
        privateCustomer: 'Privatkunde'
      },
      validity: {
        requested: 'Angefragt am',
        valid: 'Angebot gültig bis',
        remaining: 'Verbleibend',
        accepted: 'Akzeptiert am',
        canceled: 'Abgebrochen am',
        declined: 'Abgelehnt am'
      },
      transitions: {
        dialog: {
          title: 'Status zu "{newState}" ändern',
          text: 'Diese Änderung kann nicht rückgängig gemacht werden!',
          cancel: 'Abbrechen',
          confirm: 'Status ändern'
        }
      },
      documents: {
        title: 'Angehängte Dokumente'
      },
      documentFolder: {
        title: 'Dokumentenmappe',
        emptyFolder: 'Diese Mappe ist leer. Möglicherweise waren zum Zeitpunkt der Anfrage keine gültigen Dokumente vorhanden.'
      },
      precedingRequest: {
        title: 'Voranfrage'
      },
      comment: {
        heading: 'Kommentar'
      },
      creationInformation: {
        heading: 'Informationen zur Erstellung',
        createdBy: 'Erstellt von',
        createdOn: 'Erstellt am'
      },
      editForm: {
        heading: 'Anfrage bearbeiten',
        fields: {
          email: {
            label: 'E-Mail',
            placeholder: 'E-Mail'
          },
          customerId: {
            label: 'Referenznummer',
            placeholder: 'Referenznummer'
          },
          mailTemplateUuid: {
            label: 'Mail-Vorlage',
            placeholder: 'Mail-Vorlage'
          }
        },
        buttons: {
          cancel: 'Abbrechen',
          submit: 'Speichern'
        },
        messages: {
          success: 'Die Anfrage wurde erfolgreich bearbeitet'
        }
      }
    }
  }
}

export default requests
