import { createClient } from './httpClient'
import config from '@/config/config'
import { normalizeRequest, normalizeResponse } from '@/helpers/api.helper'

const endpoints = () => config.get('api.endpoints.documents')

const httpClient = createClient()

httpClient.interceptors.request.use(normalizeRequest)
httpClient.interceptors.response.use(normalizeResponse)

const getDocumentHandles = () => httpClient.get(endpoints().get('list'))

const createDocumentHandle = (name, comment = '', filename = null) => {
  const data = { name, comment }

  if (filename) {
    data.filename = filename
  }

  return httpClient.post(endpoints().get('create'), data)
}

const createDocumentVersion = ({ uuid, comment = '', fileUuid, validFrom }) =>
  httpClient.post(endpoints().get('versions.create', { uuid }), { comment, validFrom, fileUuid })

const getDocumentHandle = (uuid) => httpClient.get(endpoints().get('get', { uuid }))

const updateDocumentHandle = (uuid, data) => httpClient.put(endpoints().get('update', { uuid }), data)

const deleteDocumentHandle = (uuid) => httpClient.delete(endpoints().get('delete', { uuid }))

const getDocumentVersions = (uuid) => httpClient.get(endpoints().get('versions.list', { uuid }))

const deleteDocumentVersion = (uuid, versionUuid) => httpClient.delete(endpoints().get('versions.get', { uuid, versionUuid }))

export {
  getDocumentHandles,
  createDocumentHandle,
  createDocumentVersion,
  getDocumentHandle,
  getDocumentVersions,
  updateDocumentHandle,
  deleteDocumentHandle,
  deleteDocumentVersion
}
