import axios from 'axios'

import config from '@/config/config'
import store from '@/store'

const baseUrl = () => config.get('api.base')

const defaultOptions = () => ({
  baseURL: baseUrl(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const getAuthToken = () => store.getters['keycloak/token']

const authInterceptor = (request) => {
  const token = getAuthToken()
  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }
  return request
}

const createClient = (options) => {
  const axiosOptions = Object.assign({}, defaultOptions(), options)

  const client = axios.create(axiosOptions)
  client.interceptors.request.use(authInterceptor)

  return client
}

export { createClient }
