import upperFirst from 'lodash/upperFirst'
import isFunction from 'lodash/isFunction'

const Logger = {
  install (Vue, options) {
    const isProduction = options.isProduction
    const dummyMethod = () => {}

    Object.keys(console)
      .forEach((key) => {
        const fn = console[key]
        if (isFunction(fn)) {
          Vue[`c${upperFirst(key)}`] = !isProduction
            ? fn
            : dummyMethod

          Vue.prototype[`$c${upperFirst(key)}`] = !isProduction
            ? fn
            : dummyMethod
        }
      })
  }
}

export default Logger
