import isDate from 'lodash/isDate'
import isString from 'lodash/isString'
import { isWithinInterval, formatDistanceToNowStrict, addDays, addSeconds, startOfDay, format } from 'date-fns'
import { toDate, zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'

import config from '@/config/config'

// appends a "Z" if timezone information is missing from datetime string
export function normalizeISO8601Timezone (dateTimeString) {
  return dateTimeString.match(/([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)$/) ? dateTimeString : `${dateTimeString}Z`
}

export function timeIsWithin (datetime, firstDate, secondDate) {
  let base = datetime
  let start = firstDate
  let end = secondDate

  if (isString(base)) {
    base = toDate(normalizeISO8601Timezone(base))
  }

  if (isString(start)) {
    start = toDate(normalizeISO8601Timezone(start))
  }

  if (isString(end)) {
    end = toDate(normalizeISO8601Timezone(end))
  }

  if (start > end) {
    const tmp = start
    start = end
    end = tmp
  }

  return isWithinInterval(base, { start, end })
}

export function currentTimeIsWithin (firstDate, secondDate) {
  return timeIsWithin(Date.now(), firstDate, secondDate)
}

export function toLocaleDateString (date, options = null, locale = null) {
  const defaultOptions = config.getRaw('i18n.defaultLocalDateStringOptions')

  if (!locale) {
    locale = config.get('i18n.dateTimeLocale')
  }

  if (!isDate(date)) {
    return toDate(normalizeISO8601Timezone(date)).toLocaleDateString(locale, options ?? defaultOptions)
  }

  return date.toLocaleDateString(locale, options ?? defaultOptions)
}

export function getDate (date) {
  if (isDate(date)) {
    return date
  }

  if (isString(date)) {
    return new Date(Date.parse(date))
  }

  return new Date()
}

export function getDaysUntil (date, defaultValue = 0) {
  const locale = config.getRaw('i18n.dateFnsLocale')

  let parsedDate

  if (isString(date)) {
    if (date.length < 1) {
      parsedDate = addDays(new Date(), defaultValue)
    } else {
      parsedDate = getDate(normalizeISO8601Timezone(date))
    }
  } else {
    parsedDate = getDate(date)
  }

  return formatDistanceToNowStrict(parsedDate, { unit: 'day', roundingMethod: 'floor', locale })
}

export function createValidDateFromApi (dateString) {
  if (!dateString) {
    return dateString
  }

  const utcDate = new Date(normalizeISO8601Timezone(dateString))

  return utcToZonedTime(utcDate, config.get('i18n.timezone'))
}

export function createValidDateStringFromApi (dateString) {
  if (!dateString) {
    return dateString
  }

  const date = createValidDateFromApi(dateString)

  return toLocaleDateString(date, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

export function createValidDateStringFromPicker (dateString, to = false, allowSameDate = false) {
  if (!dateString) {
    return dateString
  }

  const zonedDate = startOfDay(new Date(dateString))

  let date = zonedTimeToUtc(zonedDate, config.get('i18n.timezone'))

  if (to) {
    date = addDays(date, 1)
  }

  if (allowSameDate) {
    const now = zonedTimeToUtc(new Date(), config.get('i18n.timezone'))
    if (now >= date) {
      /** @TODO: let api handle this */
      date = addSeconds(now, 5)
    }
  }

  return date.toISOString()
}

export function getToday () {
  return format(new Date(), 'yyyy-MM-dd')
}

export function getTomorrow () {
  return format(addDays(new Date(), 1), 'yyyy-MM-dd')
}
