const shortenUuid = (uuid) => {
  const padding = '…' // horizontal ellipsis
  return `${uuid.slice(0, 4)}${padding}${uuid.slice(-4)}`
}

const scrollToElement = (element, offset = 50) => {
  const domElement = element._isVue !== undefined ? element.$el : element
  const top = domElement.getClientRects()[0]?.top - offset - document.body.getClientRects()[0]?.top
  window.scrollTo({
    top,
    behavior: 'smooth'
  })
}

const cleanObject = (obj, remove = [null]) => {
  const result = {}
  Object.keys(obj)
    .forEach((key) => {
      if (remove.indexOf(obj[key]) < 0) {
        if (typeof obj[key] === 'object') {
          const nestedObj = cleanObject(obj[key])
          if (Object.keys(nestedObj).length > 0) {
            result[key] = nestedObj
          }
        } else {
          result[key] = obj[key]
        }
      }
    })
  return result
}

export {
  shortenUuid,
  scrollToElement,
  cleanObject
}
