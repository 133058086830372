import { mdiAlertCircleOutline, mdiInformationOutline, mdiCheckCircleOutline, mdiAlertOutline } from '@mdi/js'

export default Object.freeze({
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  colors: {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning'
  },
  icons: {
    error: mdiAlertCircleOutline,
    info: mdiInformationOutline,
    success: mdiCheckCircleOutline,
    warning: mdiAlertOutline
  }
})
